import "./../App.css";
import {ConvertTime} from "./../Methods.js";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {TableButton} from "./TableButton.js";

export const Table = ({state,changeOrder,eventItem,activityGroupItem,CheckInUser,users,userType,activityItem}) => {
  const navigate = useNavigate();
  const [waitingCheck, setWaitingCheck] = useState(false);
  const [userItem, setUserItem] = useState(null);
  const WaitingSet = (item) => {
    setUserItem(item);
    setWaitingCheck(true);
  }
  return (
    <div>
      <table cellPadding="0" cellSpacing="0">
        <thead>
          <tr className="bold">
            <td>First name <TableButton state={state} changeOrder={changeOrder} name="firstName" /></td>
            <td>Last name <TableButton state={state} changeOrder={changeOrder} name="lastName" /></td>
            <td>Email <TableButton state={state} changeOrder={changeOrder} name="email" /></td>
            <td>Registered <TableButton state={state} changeOrder={changeOrder} name="timeStamp" /></td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>
          {users
          .sort((a, b) => state.reverse ? a[state.sortParticipant] < b[state.sortParticipant] : a[state.sortParticipant] > b[state.sortParticipant] ? 1 : -1)
          .map((item,index) => (
            <tr className={item.checkedIn ? "user participating" : "user"} key={index}>
              <td className="bold">{item.firstName}</td>
              <td className="bold">{item.lastName}</td>
              <td className={item.emailError ? "emailError" : ""}>{item.email}</td>
              <td><span className="showMobile">Registered date: </span>{ConvertTime(item.timeStamp)}</td>
              <td>
                <button onClick={() => navigate("/edit-participant/" + eventItem.eventID + "/" + activityGroupItem.activityGroupID + "/" + activityItem.activityID + "/" + userType + "/" + item.userID)}>View{ /*<i className="far fa-edit"></i>*/}</button>
                {item.userType !== "cancelled" && state.role === "reception" && <button className={item.checkedIn ? "green participating fixed" : "participating fixed"} onClick={() => (userType === "waiting" && !item.checkedIn) ? WaitingSet(item) : CheckInUser(item)}>{item.checkedIn ? "Participating" : "Check-in"}{ /*<i className="fas fa-check"></i>*/}</button>}
                {item.emailError && <button className="emailError" onClick={() => navigate("/edit-participant/" + eventItem.eventID + "/" + activityGroupItem.activityGroupID + "/" + activityItem.activityID + "/" + userType + "/" + item.userID)}>!</button>}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {waitingCheck && <div className="modal">
        <div>
          <p>Are you sure?</p>
          <button onClick={() => { CheckInUser(userItem); setWaitingCheck(false)}}>OK</button>
          <button onClick={() => setWaitingCheck(false)}>Cancel</button>
        </div>
      </div>}
    </div>
  )
}
