import "./../App.css";
import {MinsToStr,StrToMins, CompareTimeStamp} from "./../Methods.js";
import { useNavigate } from "react-router-dom";

export const Card = ({item,state}) => {
  const registeredUsers = Object.values(state.users).filter((user) => user.activityID === item.activityID && user.userType === "confirmed").sort(CompareTimeStamp);
  const waitingUsers = Object.values(state.users).filter((user) => user.activityID === item.activityID && user.userType === "waiting").sort(CompareTimeStamp);
  const registeredNum = registeredUsers.length || 0;
  const waitingListNum = waitingUsers.length || 0;
  const checkedInWait = waitingUsers.filter(user => user.checkedIn).length || 0;
  const checkedInReg = registeredUsers.filter(user => user.checkedIn).length || 0;
  const checkedInNum = checkedInWait + checkedInReg;
  const endTime = MinsToStr(StrToMins(item.duration) + StrToMins(item.startTime));
  const navigate = useNavigate();
  return (
    <div className={!item.open ? "card home red" : item.ticketsLeft > 0 ? "card home green" : "card home amber"}>
      <div className="justify">
        <h3 className="limit">{item.activityName}</h3>
        <div className="justifyMobile">
          {(state.role === "super" || state.role === "manager") && <button className="home half edit" onClick={() => navigate("/edit-activity/" + item.eventID + "/" + item.activityGroupID + "/" + item.activityID)}>Edit activity{/* <i className="far fa-edit"></i>*/}</button>}
          {state.role === "reception" && <button className={!item.open ? "home red half" : item.ticketsLeft < 1 ? "home amber half" : "home green half"} disabled={!item.open} onClick={() => navigate("/register-activity/" + item.eventID + "/" + item.activityGroupID + "/" + item.activityID)}>{!item.open ? "Closed" : item.ticketsLeft < 1 ? "Waiting list" : "Register"}</button>}
          <button className={state.role === "reception" ? "home noMargin half" : "home noMargin half"} onClick={() => navigate("/activity-overview/" + item.eventID + "/" + item.activityGroupID + "/" + item.activityID)}>Manage activity</button>
        </div>
      </div>
      {item.activityComment && <span className="comment"><i>{item.activityComment}</i></span>}
      {!item.activityComment && <div className="marginTop"/>}
      <div className="justify">
        <h4>{item.startDate.split("-").reverse().join("-")} | {item.startTime}hrs - {endTime}hrs ({item.duration.replace(":","h")})</h4>
        <div className="stats">
          <span className="bold">Registrations: {registeredNum} / {item.capacity}</span>
          <span className="bold">Waiting list: {waitingListNum} / {item.capacityWait || 0}</span>
          <span className="bold">Participating: {checkedInNum} / {item.capacity}</span>
        </div>
      </div>
    </div>
  )
}
