import React, { useState, useEffect } from 'react';
import {Header} from "./components/Header.js";
import {Modal} from "./components/Modal.js";
import {LoginPage} from "./pages/LoginPage.js";
import {HomePage} from "./pages/HomePage.js";
import {NotFound} from "./pages/NotFound.js";
import {EventDashboardPage} from "./pages/EventDashboardPage.js";
import {NewEventPage} from "./pages/NewEventPage.js";
import {NewActivityGroupPage} from "./pages/NewActivityGroupPage.js";
import {NewActivityPage} from "./pages/NewActivityPage.js";
import {EditEventPage} from "./pages/EditEventPage.js";
import {EditActivityGroupPage} from "./pages/EditActivityGroupPage.js";
import {EditActivityPage} from "./pages/EditActivityPage.js";
import {EmailActivityPage} from "./pages/EmailActivityPage.js";
import {EditAdminUserPage} from "./pages/EditAdminUserPage.js";
import {EditAdminUsersPage} from "./pages/EditAdminUsersPage.js";
import {RegisterAdminUserPage} from "./pages/RegisterAdminUserPage.js";
import {SearchUsersPage} from "./pages/SearchUsersPage.js";
import {DataExportPage} from "./pages/DataExportPage.js";
import {ActivityOverviewPage} from "./pages/ActivityOverviewPage.js";
import {RegisterUserPage} from "./pages/RegisterUserPage.js";
import {SelfRegisterUserPage} from "./pages/SelfRegisterUserPage.js";
import {SelfRegisterEventPage} from "./pages/SelfRegisterEventPage.js";
import {SelfCancelUserPage} from "./pages/SelfCancelUserPage.js";
// import {QRTestPage} from "./pages/QRTestPage.js";
import {EditUserPage} from "./pages/EditUserPage.js";
import {SelfRegLinks} from "./pages/SelfRegLinks.js";
import {SelfRegLinksEvent} from "./pages/SelfRegLinksEvent.js";
import { Routes, Route, Navigate, useNavigate, useLocation} from "react-router-dom";
import LoadingIcons from "react-loading-icons";
import {db, auth} from './firebase';
import "./App.css";
import Papa from 'papaparse';
import data from "./translations.csv";

// Firebase
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { collection, query, where, getDocs, enableIndexedDbPersistence, onSnapshot } from "firebase/firestore";

export const App = () => {
  // State and hooks
  const [translation, setTranslation] = useState();
  const [state, setState] = useState({
    isLoading: true,
    emailConfirmation: true,
    email: "",
    password: "",
    eventName: "",
    activityGroupName: "",
    activityName: "",
    activityComment: "",
    activityLocation: "",
    calendarNote: "",
    startDate: "",
    endDate: "",
    startTime: "",
    taskName: "",
    taskNameTwo: "",
    duration: "",
    capacity: "",
    capacityWait: "",
    countryField: "",
    institutionField: "",
    commentField: "",
    titleField: "",
    addressField: "",
    phoneField: "",
    countryRequired: "",
    institutionRequired: "",
    commentRequired: "",
    titleRequired: "",
    addressRequired: "",
    phoneRequired: "",
    country: "",
    institution: "",
    comment: "",
    title: "",
    address: "",
    phone: "",
    firstName: "",
    lastName: "",
    open: "",
    gdpr: "",
    optIn: "",
    showLoginError: false,
    loginError: "",
    eventLogo: "",
    selfReg: true,
    sendReminder: "",
    emailUser: "",
    sendEmail: "",
    sendType: "",
    emailSubject: "",
    emailBody: "",
    timeZone: "",
    admin: "",
    sort: "date",
    sortParticipant: "timeStamp",
    reverse: false,
    multiReg: "",
    language: "english"
    // loggedInUser: ""
  });

  let navigate = useNavigate();
  const location = useLocation();

  // Startup
  useEffect(() => {
    enableIndexedDbPersistence(db)
      .catch((err) => {
          if (err.code === 'failed-precondition') {
            setState((prevState) => ({
              ...prevState,
              showOfflineWarning: true,
              offlineWarning: "Please note: The site will only work offline in one tab at a time!"
            }));
          } else if (err.code === 'unimplemented') {
            setState((prevState) => ({
              ...prevState,
              showOfflineWarning: true,
              offlineWarning: "Please note: The current browser does not support all of the features required to work offline."
            }));
          }
      });
  },[]);
  useEffect(() => {
    async function getData() {
        const response = await fetch(data);
        const reader = response.body.getReader();
        const result = await reader.read(); // raw array
        const decoder = new TextDecoder("utf-8");
        const csv = decoder.decode(result.value); // the csv text
        const results = Papa.parse(csv, { header: true }); // object with { data, errors, meta }
        const obj = {};
        results.data.forEach((result) => { obj[result.key] = result });
        setTranslation(obj);
    }
    getData();
}, []);
  useEffect(() => {
      const unsubscribe = onAuthStateChange();
      return () => {
        //console.log("unsubscribe()");
        unsubscribe();
      }
  },[]);
  useEffect(() => {
      const unsubscribeEvents = onSnapshot(collection(db, "events"), (querySnapshot) => {
        const events = {};
        querySnapshot.forEach((doc) => {
            events[doc.data().eventID] = doc.data();
        });
        setState((prevState) => ({
          ...prevState,
          events: events
        }));
      });
      return () => {
        // console.log("unsubscribeEvents()");
        unsubscribeEvents();
      }
  },[]);

  useEffect(() => {
    const unsubscribeGroups = onSnapshot(collection(db, "activityGroups"), (querySnapshot) => {
      const activityGroups = {};
        querySnapshot.forEach((doc) => {
          activityGroups[doc.data().activityGroupID] = doc.data();
        });
        setState((prevState) => ({
          ...prevState,
          activityGroups: activityGroups
        }));
    });
    return () => {
      // console.log("unsubscribeGroups()");
      unsubscribeGroups();
    }
},[]);

useEffect(() => {
  const unsubscribeActivities = onSnapshot(collection(db, "activities"), (querySnapshot) => {
    const activities = {};
    querySnapshot.forEach((doc) => {
      activities[doc.data().activityID] = doc.data();
    });
    setState((prevState) => ({
      ...prevState,
      activities: activities
    }));
  });
  return () => {
    // console.log("unsubscribeActivities()");
    unsubscribeActivities();
  }
},[]);

  // Auth / log in
  const onAuthStateChange = () => {
    return onAuthStateChanged(auth, (user) => {
      // console.log("onAuthStateChange");
      if (user) {
        // User is signed in
        const unsubscribeUsers = onSnapshot(collection(db, "users"), (querySnapshot) => {
          const users = {};
          querySnapshot.forEach((doc) => {
              users[doc.data().userID] = doc.data();
          });
          setState((prevState) => ({
            ...prevState,
            users: users,
            unsubscribeUsers
          }));
        });
        CheckUser(user);
      } else {
        // Or not
        setState((prevState) => ({
          ...prevState,
          loggedIn: false,
          role: null,
          isLoading: false,
          receptionEventID: null,
          users: null
        }));
      }
    });
  }

  const Login = (email, password) => {
    // console.log("Login");
    setState((prevState) => ({
      ...prevState,
      isLoading: true
    }));
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        ShowModal("alert", "Logged in");
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          loginError: "Error: " + error.message.substring(22,error.message.length - 2),
          showLoginError: true
        }));
      });
  }
  const CheckUser = async (user) => {
    // console.log("CheckUser");
    const q = query(collection(db, "users"), where("userID", "==", user.uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setState((prevState) => ({
        ...prevState,
        email: "",
        password: "",
        loginError: "",
        showLoginError: false,
        loggedIn: true,
        role: doc.data().role,
        isLoading: false,
        // loggedInUserID: user.uid,
        receptionEventID: doc.data().eventID || null,
      }));
    });
    location.pathname.includes("login") ? navigate("/") : navigate(location.pathname);
    // navigate("/");
  }
  const Logout = () => {
    // navigate("../login", {replace: true});
    navigate("/");
    navigate("../login");
    window.history.replaceState({}, '');
    state.unsubscribeUsers();
    setState((prevState) => ({
      ...prevState,
      isLoading: true
    }));
    signOut(auth).then(() => {
      setState((prevState) => ({
        ...prevState,
        loggedIn: false,
        role: "reception",
        isLoading: false,
        receptionEventID: null,
      }));
      ResetFields();
      ShowModal("alert", "Logged out");
    }).catch((error) => {
      // An error happened.
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    });
  }
  const Back = (home) => {
    (location.key ==="default" || home) ? navigate("/") : navigate(-1);
    ResetFields();
  }
  const ResetFields = () => {
    setState((prevState) => ({
      ...prevState,
      email: "",
      password: "",
      eventName: "",
      activityGroupName: "",
      activityName: "",
      activityComment: "",
      activityLocation: "",
      calendarNote: "",
      startDate: "",
      endDate: "",
      startTime: "",
      taskName: "",
      taskNameTwo: "",
      duration: "",
      capacity: "",
      capacityWait: "",
      countryField: "",
      institutionField: "",
      commentField: "",
      titleField: "",
      addressField: "",
      phoneField: "",
      countryRequired: "",
      institutionRequired: "",
      commentRequired: "",
      titleRequired: "",
      addressRequired: "",
      phoneRequired: "",
      country: "",
      institution: "",
      comment: "",
      title: "",
      address: "",
      phone: "",
      firstName: "",
      lastName: "",
      open: "",
      gdpr: "",
      optIn: "",
      emailConfirmation: true,
      selfReg: true,
      sendReminder: "",
      showLoginError: false,
      loginError: "",
      eventLogo: "",
      emailUser: "",
      sendEmail: "",
      sendType: "",
      emailSubject: "",
      emailBody: "",
      timeZone: "",
      admin: "",
      multiReg: "",
      language: "english"
    }))
  }
  // Modals
  const ShowModal = (modalType, modalContent, modalTitle, modalContentTwo) => {
    setState((prevState) => ({
      ...prevState,
      modalContent: modalContent,
      modalContentTwo: modalContentTwo,
      modalTitle: modalTitle,
      modalType: modalType,
      modal: true
    }));
  }
  const HideModal = (ok) => {
    setState((prevState) => ({
      ...prevState,
      modal: false
    }));
  }
  // Inputs
  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setState((prevState) => ({
      ...prevState,
      [target.name]: value
    }));
    if(target.name === "titleField" && !value) setState((prevState) => ({...prevState, titleRequired: false}));
    if(target.name === "phoneField" && !value) setState((prevState) => ({...prevState, phoneRequired: false}));
    if(target.name === "institutionField" && !value) setState((prevState) => ({...prevState, institutionRequired: false}));
    if(target.name === "addressField" && !value) setState((prevState) => ({...prevState, addressRequired: false}));
    if(target.name === "countryField" && !value) setState((prevState) => ({...prevState, countryRequired: false}));
    if(target.name === "commentField" && !value) setState((prevState) => ({...prevState, commentRequired: false}));
  }

  const changeOrder = (value) => {
    if (state.sortParticipant === value) {
      setState((prevState) => ({
        ...prevState,
        reverse: !state.reverse
      }));
    }
    else setState((prevState) => ({
      ...prevState,
      sortParticipant: value,
      reverse: false
    }));
  }

  const Language = (value) => {
    setState((prevState) => ({
      ...prevState,
      language: value
    }));
  }

  return (
    <div className="App">
      <Header state={state} Logout={Logout} Language={Language} />
      {<div className="page">
        {state.showOfflineWarning && !location.pathname.includes("self") && <p className="warning">{state.offlineWarning}</p>}
        {!state.isLoading && state.events && state.activityGroups && state.activities && <Routes>
          <Route path="/"
            element={state.loggedIn && state.users ? <HomePage state={state} handleChange={handleChange} /> : <Navigate to="/login" />
          }/>
          <Route path="/new-event"
            element={state.loggedIn && state.users ? <NewEventPage Back={Back} state={state} ShowModal={ShowModal} ResetFields={ResetFields} handleChange={handleChange} /> : <Navigate to="/login" />
          }/>
          <Route path="/new-activity-group/:eventID"
            element={state.loggedIn && state.users ? <NewActivityGroupPage Back={Back} setState={setState} ShowModal={ShowModal} ResetFields={ResetFields} state={state} handleChange={handleChange} /> : <Navigate to="/login" />
          }/>
          <Route path="/new-activity/:eventID/:activityGroupID"
            element={state.loggedIn && state.users ? <NewActivityPage Back={Back} setState={setState} ShowModal={ShowModal} state={state} ResetFields={ResetFields} handleChange={handleChange} /> : <Navigate to="/login" />
          }/>
          <Route path="/edit-event/:eventID"
            element={state.loggedIn && state.users ? <EditEventPage Back={Back} setState={setState} state={state} ShowModal={ShowModal} ResetFields={ResetFields} handleChange={handleChange} /> : <Navigate to="/login" />
          }/>
          <Route path="/edit-activity-group/:eventID/:activityGroupID"
            element={state.loggedIn && state.users ? <EditActivityGroupPage Back={Back} state={state} ShowModal={ShowModal} ResetFields={ResetFields} setState={setState} handleChange={handleChange} /> : <Navigate to="/login" />
          }/>
          <Route path="/edit-activity/:eventID/:activityGroupID/:activityID"
            element={state.loggedIn && state.users ? <EditActivityPage Back={Back} ResetFields={ResetFields} setState={setState} state={state} ShowModal={ShowModal} handleChange={handleChange} /> : <Navigate to="/login" />
          }/>
          <Route path="/activity-overview/:eventID/:activityGroupID/:activityID"
            element={state.loggedIn && state.users ? <ActivityOverviewPage Back={Back} setState={setState} state={state} changeOrder={changeOrder} ResetFields={ResetFields} handleChange={handleChange} ShowModal={ShowModal} /> : <Navigate to="/login" />
          }/>
          <Route path="/register-activity/:eventID/:activityGroupID/:activityID"
            element={state.loggedIn && state.users ? <RegisterUserPage translation={translation} Back={Back} setState={setState} state={state} handleChange={handleChange} ShowModal={ShowModal} ResetFields={ResetFields} /> : <Navigate to="/login" />
          }/>
          <Route path="/admin"
            element={state.loggedIn && state.users ? <EditAdminUsersPage Back={Back} state={state} handleChange={handleChange} ShowModal={ShowModal} /> : <Navigate to="/login" />
          }/>
          <Route path="/edit-admin/:userID"
            element={state.loggedIn && state.users ? <EditAdminUserPage Back={Back} setState={setState} state={state} ShowModal={ShowModal} ResetFields={ResetFields} handleChange={handleChange} /> : <Navigate to="/login" />
          }/>
          <Route path="/email-participants/:eventID/:activityGroupID/:activityID"
            element={state.loggedIn && state.users ? <EmailActivityPage Back={Back} state={state} handleChange={handleChange} ResetFields={ResetFields} ShowModal={ShowModal} /> : <Navigate to="/login" />
          }/>
          <Route path="/edit-participant/:eventID/:activityGroupID/:activityID/:userType/:userID"
            element={state.loggedIn && state.users ? <EditUserPage translation={translation} Back={Back} state={state} handleChange={handleChange} ResetFields={ResetFields} ShowModal={ShowModal} setState={setState} /> : <Navigate to="/login" />
          }/>
          {/* <Route path="/register-admin" */}
          <Route path="/register-admin/:eventID"
            element={state.loggedIn && state.users ? <RegisterAdminUserPage Back={Back} state={state} setState={setState} ResetFields={ResetFields} handleChange={handleChange} ShowModal={ShowModal} /> : <Navigate to="/login" />
          }/>
          <Route path="/search-participants/:eventID"
            element={state.loggedIn && state.users ? <SearchUsersPage Back={Back} state={state} /> : <Navigate to="/login" />
          }/>
          <Route path="/data-export"
            element={state.loggedIn && state.users ? <DataExportPage Back={Back} state={state} /> : <Navigate to="/login" />
          }/>
          <Route path="/registration-links"
            element={state.loggedIn && state.users ? <SelfRegLinks Back={Back} state={state} /> : <Navigate to="/login" />
          }/>
          <Route path="/registration-links-event/:eventID"
            element={state.loggedIn && state.users ? <SelfRegLinksEvent Back={Back} state={state} /> : <Navigate to="/login" />
          }/>
          <Route path="/event-dashboard"
            element={state.loggedIn && state.users ? <EventDashboardPage Back={Back} setState={setState} state={state} handleChange={handleChange} ShowModal={ShowModal} ResetFields={ResetFields}/> : <Navigate to="/login" />
          }/>
          <Route path="/login"
            element={<LoginPage state={state} Login={Login} handleChange={handleChange} />
          }/>
          <Route path="/self-register/:eventID/:activityGroupID/:activityID"
            element={<SelfRegisterUserPage translation={translation} Back={Back} setState={setState} state={state} handleChange={handleChange} ShowModal={ShowModal} ResetFields={ResetFields} />
          }/>
          <Route path="/self-register/:eventID"
            element={<SelfRegisterEventPage translation={translation} Back={Back} setState={setState} state={state} handleChange={handleChange} ShowModal={ShowModal} ResetFields={ResetFields} />
          }/>
          <Route path="/self-cancel/:eventID/:activityGroupID/:activityID/:userID"
            element={<SelfCancelUserPage translation={translation} Back={Back} setState={setState} state={state} handleChange={handleChange} ShowModal={ShowModal} ResetFields={ResetFields} />
          }/>
          <Route path="*" element={<NotFound />} />
        </Routes>}
      </div>}
      {state.isLoading && <div className="loading">
        <LoadingIcons.Oval height="30" width="65" stroke="#292B63" strokeWidth="5" speed={.75} />
      </div>}
      {state.modal &&
        <Modal state={state} HideModal={HideModal} />
      }
    </div>
  )
}
