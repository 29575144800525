import React, { useEffect, useState } from 'react';
import "./../App.css";
import {FormInputGroup} from "./../components/FormInputGroup.js";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import {db, storage} from './../firebase';
import LoadingIcons from "react-loading-icons";
import { doc, updateDoc, deleteDoc, query, collection, where, getDocs } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes, deleteObject, listAll } from "firebase/storage";
import { zonedTimeToUtc } from 'date-fns-tz';
import {CompareTimeStamp} from "./../Methods.js";

export const EditActivityPage = ({state,setState,ShowModal,Back,handleChange,ResetFields}) => {

  useEffect(() => {
    ViewEditActivityPage();
    setEventStartDate(state.events[eventID].startDate);
    setEventEndDate(state.events[eventID].endDate);
  },[]);

  const navigate = useNavigate();
  const location = useLocation();
  const { eventID, activityGroupID, activityID } = useParams();
  const [eventStartDate, setEventStartDate] = useState();
  const [eventEndDate, setEventEndDate] = useState();
  const [deleteCheck, setDeleteCheck] = useState(false);
  const eventItem = state.events[eventID];
  const activityGroupItem = state.activityGroups[activityGroupID];
  const activityItem = state.activities[activityID];
  const activityRef = doc(db, "activities", activityID);
  const [file, setFile] = useState(null);
  const [url, setURL] = useState("");
  const [pdfLoaded, setPdfLoaded] = useState(false);
  const dateFormat = new Date(state.startDate + "T" + state.startTime);
  const timeStampStartDate = zonedTimeToUtc(dateFormat, eventItem.timeZone);
  const timeStamp = Date.parse(timeStampStartDate);
  const reminderTimeOne = timeStamp - (60*60*1000*24);
  const reminderTimeTwo = timeStamp - (60*60*1000);
  const reminderTimeOneLocal = new Date(reminderTimeOne);
  const reminderTimeTwoLocal = new Date(reminderTimeTwo);

  const ViewEditActivityPage = () => {
    if (!eventItem || !activityGroupItem || !activityItem) return;
    setState((prevState) => ({
      ...prevState,
      activityName: activityItem.activityName,
      activityComment: activityItem.activityComment,
      activityLocation: activityItem.activityLocation,
      calendarNote: activityItem.calendarNote,
      eventName: eventItem.eventName,
      startDate: activityItem.startDate,
      startTime: activityItem.startTime,
      oldStartDate: activityItem.startDate,
      oldStartTime: activityItem.startTime,
      // taskName: activityItem.taskName,
      // taskNameTwo: activityItem.taskNameTwo,
      duration: activityItem.duration,
      reminderTimeOne: activityItem.reminderTimeOne,
      reminderTimeTwo: activityItem.reminderTimeTwo,
      capacity: activityItem.capacity,
      capacityWait: activityItem.capacityWait,
      activityGroupName: activityGroupItem.activityGroupName,
      open: activityItem.open,
      selfReg: activityItem.selfReg,
      sendReminder: activityItem.sendReminder
    }));
    if (activityItem.pdf) {
      const pdfRef = ref(storage, 'activity-pdfs/' + eventID + '/' + activityGroupID + '/' + activityID + '/' + activityItem.pdf);
      // Get the download URL
      getDownloadURL(pdfRef)
        .then((url) => {
          setURL(url);
          setPdfLoaded(true);
        })
        .catch((error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/object-not-found':
              // File doesn't exist
              break;
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;

            // ...

            case 'storage/unknown':
              // Unknown error occurred, inspect the server response
              break;
          }
          setPdfLoaded(true);
        });
    }
    else setPdfLoaded(true);
  }

  // useEffect(() => {
  //   if (!file) return;
  //   const path = `/activity-pdfs/${eventID}/${activityGroupID}/${activityID}/${file.name}`;
  //   const storageRef = ref(storage, path);
  //   // 'file' comes from the Blob or File API
  //   uploadBytes(storageRef, file).then((snapshot) => {
  //     // Upload completed successfully, now we can get the download URL
  //     getDownloadURL(snapshot.ref).then((downloadURL) => {
  //       setURL(downloadURL);
  //     });
  //   });
  // },[file]);

  const HandleImageAsFile = (e) => {
    if (e.target.files[0]) setFile(e.target.files[0]);
  }

  const FieldCheck = () => {
    if (!state.activityName || !state.activityLocation || !state.startDate || !state.duration || !state.capacity || !state.capacityWait) ShowModal("alert", "Please complete all required fields");
    // else if (state.open && Date.parse(state.startDate + "T" + state.startTime) < Date.now()) ShowModal("alert", "Please edit - activity start time cannot be in the past");
    else EditActivity(eventID,activityGroupID,activityID);
  }

  const DeleteActivity = () => {
    deleteDoc(activityRef);
    DeleteActivityUsers(activityID);
    DeleteStorage(`/qr-tickets/${eventID}/${activityGroupID}/${activityID}/`);
    DeleteStorage(`activity-pdfs/${eventID}/${activityGroupID}/${activityID}/`);
    navigate("/");
    ResetFields();
  }

  const DeletePDF = () => {
    if (activityItem.pdf) {
      // Create a reference to the file to delete
      const pdfRef = ref(storage, 'activity-pdfs/' + eventID + '/' + activityGroupID + '/' + activityID + '/' + activityItem.pdf);
      // Delete the file
      deleteObject(pdfRef).then(() => {
        // File deleted successfully
        setURL(null);
        setPdfLoaded(true);
        updateDoc(activityRef,{pdf:null});
      }).catch((error) => {
        // Uh-oh, an error occurred!
      });
    }
  }

  const DeleteStorage = async (path) => {
    const listRef = ref(storage, path);
    // Find all the prefixes and items.
    listAll(listRef)
    .then((res) => {
      res.items.forEach((itemRef) => {
        // Delete the file
        deleteObject(itemRef).then(() => {
          // File deleted successfully
        }).catch((error) => {
          // Uh-oh, an error occurred!
        });
      });
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });
  }

  const DeleteActivityUsers = async (activityID) => {
    // const userIDs = {};
    const q = query(collection(db, "users"), where("activityID", "==", activityID));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      deleteDoc(doc.ref);
    });
  }

  const EditActivity = async (eventID,activityGroupID,activityID) => {
    const registeredUsers = Object.values(state.users).filter((item) => item.activityID === activityID && item.userType === "confirmed").sort(CompareTimeStamp);
    const registeredNum = registeredUsers.length || 0;
    updateDoc(activityRef,{
      "activityName": state.activityName,
      "activityComment": state.activityComment,
      "activityLocation": state.activityLocation,
      "calendarNote": state.calendarNote,
      "eventID": eventID,
      "activityGroupID": activityGroupID,
      "activityID": activityID,
      "startDate": state.startDate,
      "startTime": state.startTime,
      // "taskName": state.taskName || null,
      // "taskNameTwo": state.taskNameTwo || null,
      "reminderTimeOne": state.sendReminder ? reminderTimeOne : null,
      "reminderTimeTwo": state.sendReminder ? reminderTimeTwo : null,
      "duration": state.duration,
      "capacity": state.capacity,
      "capacityWait": state.capacityWait,
      "open": state.open,
      "selfReg": state.selfReg,
      "ticketsLeft": Number(state.capacity) - registeredNum,
      "sendReminder": state.sendReminder,
      "pdf": file ? `${file.name}` : activityItem.pdf || null
    });
    if (file) {
      const path = `/activity-pdfs/${eventID}/${activityGroupID}/${activityID}/${file.name}`;
      const storageRef = ref(storage, path);
      // 'file' comes from the Blob or File API
      uploadBytes(storageRef, file).then((snapshot) => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          // setURL(downloadURL);
        });
      });
    }
    location.key ==="default" ? navigate("/") : navigate(-1);
    ResetFields();
  }

  if (activityItem) return (
    <div>
      <button className="secondary" onClick={() => Back()}>Back</button>
      {!pdfLoaded && <div className="loading">
        <LoadingIcons.Oval height="30" width="65" stroke="#292B63" strokeWidth="5" speed={.75} />
      </div>}
      {pdfLoaded && <section>
        <h1>Edit activity</h1>
        <form onSubmit={(e) => {e.preventDefault(); FieldCheck()}}>
          {(state.role === "super" || state.role === "manager") && <div>
            <p><small>PDF - About Activity</small></p>
            {url && <p><small><a href={url} target="_blank" rel="noreferrer">{activityItem.pdf}</a></small></p>}
            <div className="fileInput">
              {url && <button onClick={(e) => {e.preventDefault(); DeletePDF()}}>Delete PDF</button>}
              <FormInputGroup type="file" text="pdf" title="" handleChange={HandleImageAsFile} name="activityPDF" />
            </div>
          <FormInputGroup type="textarea" value={state.eventName} disabled={true} title="Event" handleChange={null} />
          <FormInputGroup type="textarea" value={state.activityGroupName} disabled={true} title="Activity group" handleChange={null} />
          <FormInputGroup required={true} className={!state.activityName ? "required" : null} type="textarea" value={state.activityName} handleChange={handleChange} name="activityName" title="Activity name" />
          <FormInputGroup required={true} className={!state.activityLocation ? "required" : null} type="textarea" handleChange={handleChange} name="activityLocation" value={state.activityLocation} title="Activity location" />
          <FormInputGroup type="textarea" handleChange={handleChange} value={state.activityComment} title="Activity comment" name="activityComment" />
          <FormInputGroup type="textarea" handleChange={handleChange} value={state.calendarNote} title="Email calendar note" name="calendarNote" />
          <FormInputGroup required={true} className={!state.startDate ? "required" : null} type="date" min={eventStartDate} max={eventEndDate} handleChange={handleChange} value={state.startDate} title="Date" name="startDate" />
          <FormInputGroup required={true} className={!state.startTime ? "required" : null} min="07:00" max="23:00" type="time" value={state.startTime} name="startTime" handleChange={handleChange} title={"Start time (" + state.events[eventID].timeZone + ")"} />
          <FormInputGroup required={true} type="duration" className={!state.duration ? "required" : null} handleChange={handleChange} name="duration" value={state.duration} title="Duration" />
          <FormInputGroup required={true} type="number" className={!state.capacity ? "required" : null} value={state.capacity} handleChange={handleChange} name="capacity" title="Capacity" />
          <FormInputGroup required={true} type="number" className={!state.capacityWait ? "required" : null} value={state.capacityWait} handleChange={handleChange} name="capacityWait" title="Waiting list capacity" />
          </div>}
          <div className='card settings'>
            <h5>Settings</h5>
            <div className='inner'>
            <FormInputGroup type="checkbox" className="checkboxGroup" title="Registration open" checked={state.open} handleChange={handleChange} name="open" />
              <FormInputGroup type="checkbox" className="checkboxGroup" title="Self-registration open" checked={state.selfReg} handleChange={handleChange} name="selfReg" />
              <FormInputGroup title="Send reminder emails" type="checkbox" className="checkboxGroup" checked={state.sendReminder} handleChange={handleChange} name="sendReminder" />
              {state.sendReminder && state.open && <p><small>First reminder (24hrs before): {reminderTimeOneLocal.toString()}</small></p>}
              {state.sendReminder && state.open && <p><small>Second reminder (1hr before): {reminderTimeTwoLocal.toString()}</small></p>}
              {(!state.sendReminder || !state.open) && <p><small>Note: Reminder emails disabled</small></p>}
            </div>
          </div>
          <div className='justifyMobile'>
            <button type="submit" className='half'>Save activity</button>
            {(state.role === "super" || state.role === "manager") && <button className='half edit' onClick={(e) => {e.preventDefault(); setDeleteCheck(true)}}>Delete activity</button>}
          </div>
        </form>
      </section>}
      {deleteCheck && <div className="modal">
        <div>
          <p>Are you sure? This will delete any registered participants as well</p>
          <button onClick={() => { DeleteActivity(); setDeleteCheck(false)}}>OK</button>
          <button onClick={() => setDeleteCheck(false)}>Cancel</button>
        </div>
      </div>}
    </div>
  )
  else return (
    <div>
      <button className="secondary" onClick={() => Back()}>Back</button>
      <section>
        <div>
        <h1>Page not found</h1>
        <p>Activity does not exist</p>
        </div>
      </section>
    </div>
  );
}
