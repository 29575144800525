import "./../App.css";
import {FormInputGroup} from "./../components/FormInputGroup.js";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {db} from './../firebase';
import { doc, setDoc } from "firebase/firestore";
import {FirestoreAutoId} from "./../Methods.js";

export const NewActivityGroupPage = ({state,setState,ShowModal,ResetFields,Back,handleChange}) => {

  const { eventID } = useParams();
  const eventName = state.events[eventID].eventName;
  const navigate = useNavigate();
  const activityGroupID = FirestoreAutoId();
  const activityGroupRef = doc(db, "activityGroups", activityGroupID);

  useEffect(() => {
    ViewNewActivityGroupPage(eventID);
  },[]);

  const ViewNewActivityGroupPage = (eventID) => {
    setState((prevState) => ({
      ...prevState,
      multiReg: false
    }));
  }

  async function AddActivityGroup() {
    setDoc(activityGroupRef,{
      "activityGroupName": state.activityGroupName,
      "activityGroupID": activityGroupID,
      "eventID": eventID,
      "multiReg": state.multiReg
    })
    ShowModal("alert", "Activity Group added");
    navigate("/");
    ResetFields();
  }

  if (state.role !=="super" && state.role !== "manager") return (
    <div>
      <button className="secondary" onClick={() => Back(true)}>Back</button>
      <section>
        <h1>You cannot access this page</h1>
      </section>
    </div>
  )
  return (
    <div>
      <button className="secondary" onClick={() => Back(true)}>Back</button>
      <section>
        <h1>New activity group</h1>
        <form onSubmit={(e) => {e.preventDefault(); (eventID && state.activityGroupName) ? AddActivityGroup(eventID) : ShowModal("alert", "Please complete name field")}}>
          <FormInputGroup type="textarea" title="Event" disabled={true} value={eventName} />
          <FormInputGroup type="textarea" title="Name" required={true} className={!state.activityGroupName ? "required" : null} value={state.activityGroupName} handleChange={handleChange} name="activityGroupName" />
          <div className="card settings">
            <h5>Settings</h5>
            <div className="inner">
              <FormInputGroup title="Allow multiple registrations in group" type="checkbox" className="checkboxGroup" checked={state.multiReg} handleChange={handleChange} name="multiReg" />
            </div>
          </div>
          <button type="submit">Create new activity group</button>
        </form>
      </section>
    </div>
  )
}
