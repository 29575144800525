import "./../App.css";
import TimeField from 'react-simple-timefield';
import countries from "./countries.json";

export const TextAreaInput = (props) => {
  return (
    <textarea rows="3" className={props.className} disabled={props.disabled} value={props.value} onChange={props.handleChange} name={props.name} onFocus={props.OnFocus} onBlur={props.OnBlur} />
  )
}

export const DateInput = (props) => {
  return (
    <input className={props.className} disabled={props.disabled} min={props.min} max={props.max} type="date" value={props.value} onChange={props.handleChange} name={props.name} />
  )
}

export const DurationInput = (props) => {
  return (
    <TimeField className={"duration " + props.className} disabled={props.disabled} value={props.value} onChange={props.handleChange} name={props.name} />
  )
}

export const NumberInput = (props) => {
  return (
    <input className={props.className} disabled={props.disabled} type="number" min={0} value={props.value} onChange={props.handleChange} name={props.name} />
  )
}

export const TextInput = (props) => {
  return (
    // <Autocomplete suggestions={["Oranges", "Apples", "Banana", "Kiwi", "Mango"]}/>
    <input className={props.className} disabled={props.disabled} type="text" value={props.value} onChange={props.handleChange} onFocus={props.OnFocus} name={props.name} onBlur={props.OnBlur} />
  )
}

export const EmailInput = (props) => {
  return (
    <input className={props.className} disabled={props.disabled} type="email" value={props.value} onChange={props.handleChange} name={props.name} onFocus={props.OnFocus} onBlur={props.OnBlur} />
  )
}

export const FileInput = (props) => {
  return (
    <div>
      {/* <button onClick={(event) => {event.preventDefault();document.getElementById('file').click()}}>CHOOSE {props.text}</button> */}
      <input id="file" type="file" value={props.value} disabled={props.disabled} onChange={props.handleChange} name={props.name} />
    </div>
  )
}

export const TimeInput = (props) => {
  return (
    <input className={props.className} disabled={props.disabled} min={props.min} max={props.max} type="time" value={props.value} onChange={props.handleChange} name={props.name} />
  )
}

export const TimeZoneInput = (props) => {
  return (
    <div className={"select-wrapper " + props.className}>
      <select disabled={props.disabled} value={props.value} onChange={props.handleChange} name={props.name}>
        <option value="">Choose time zone</option>
        <option value="Pacific/Kwajalein">(GMT -12:00) Eniwetok, Kwajalein</option>
        <option value="Pacific/Samoa">(GMT -11:00) Midway Island, Samoa</option>
        <option value="Pacific/Honolulu">(GMT -10:00) Hawaii</option>
        <option value="Pacific/Marquesas">(GMT -9:30) Taiohae</option>
        <option value="America/Anchorage">(GMT -9:00) Alaska</option>
        <option value="America/Los_Angeles">(GMT -8:00) Pacific Time (US &amp; Canada)</option>
        <option value="America/Denver">(GMT -7:00) Mountain Time (US &amp; Canada)</option>
        <option value="America/Chicago">(GMT -6:00) Central Time (US &amp; Canada), Mexico City</option>
        <option value="America/New_York">(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima</option>
        <option value="America/Caracas">(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz</option>
        <option value="America/St_Johns">(GMT -3:30) Newfoundland</option>
        <option value="America/Sao_Paulo">(GMT -3:00) Brazil, Buenos Aires, Georgetown</option>
        <option value="America/Godthab">(GMT -2:00) Mid-Atlantic</option>
        <option value="Atlantic/Azores">(GMT -1:00) Azores, Cape Verde Islands</option>
        <option value="Europe/London">(GMT) Western Europe Time, London, Lisbon, Casablanca</option>
        <option value="Europe/Paris">(GMT +1:00) Brussels, Copenhagen, Madrid, Paris</option>
        <option value="Africa/Johannesburg">(GMT +2:00) Kaliningrad, South Africa</option>
        <option value="Europe/Moscow">(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg</option>
        <option value="Asia/Tehran">(GMT +3:30) Tehran</option>
        <option value="Asia/Tbilisi">(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi</option>
        <option value="Asia/Kabul">(GMT +4:30) Kabul</option>
        <option value="Asia/Karachi">(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent</option>
        <option value="Asia/Calcutta">(GMT +5:30) Bombay, Calcutta, Madras, New Delhi</option>
        <option value="Asia/Kathmandu">(GMT +5:45) Kathmandu, Pokhara</option>
        <option value="Asia/Colombo">(GMT +6:00) Almaty, Dhaka, Colombo</option>
        <option value="Asia/Yangon">(GMT +6:30) Yangon, Mandalay</option>
        <option value="Asia/Bangkok">(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
        <option value="Asia/Singapore">(GMT +8:00) Beijing, Perth, Singapore, Hong Kong</option>
        <option value="Australia/Eucla">(GMT +8:45) Eucla</option>
        <option value="Asia/Tokyo">(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</option>
        <option value="Australia/Adelaide">(GMT +9:30) Adelaide, Darwin</option>
        <option value="Pacific/Guam">(GMT +10:00) Eastern Australia, Guam, Vladivostok</option>
        <option value="Australia/Lord_Howe">(GMT +10:30) Lord Howe Island</option>
        <option value="Asia/Magadan">(GMT +11:00) Magadan, Solomon Islands, New Caledonia</option>
        <option value="Pacific/Norfolk">(GMT +11:30) Norfolk Island</option>
        <option value="Pacific/Auckland">(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
        <option value="Pacific/Chatham">(GMT +12:45) Chatham Islands</option>
        <option value="Pacific/Apia">(GMT +13:00) Apia, Nukualofa</option>
        <option value="Pacific/Kiritimati">(GMT +14:00) Line Islands, Tokelau</option>
      </select>
    </div>
  )
}

export const CountryInput = (props) => {
  return (
    <div className={"select-wrapper " + props.className}>
      <select disabled={props.disabled} value={props.value} onChange={props.handleChange} name={props.name} onFocus={props.OnFocus} onBlur={props.OnBlur}>
        <option value="">{props.language ? props.translation[props.language].chooseCountry : "Choose country"}</option>
        {countries[props.language || "english"].map((item,index) => (
          <option key={index} value={item.alpha2.toUpperCase()}>{item.name}</option>
        ))}
      </select>
    </div>
  )
}

export const TitleInput = (props) => {
  return (
    <div className={"select-wrapper " + props.className}>
      <select disabled={props.disabled} value={props.value} onChange={props.handleChange} name={props.name} onFocus={props.OnFocus} onBlur={props.OnBlur}>
        <option value="">{props.language ? props.translation[props.language].chooseTitle : "Choose title"}</option>
        <option value="Dr.">{props.language ? props.translation[props.language].dr : "Dr."}</option>
        <option value="Prof.">{props.language ? props.translation[props.language].prof : "Prof."}</option>
        <option value="Mr.">{props.language ? props.translation[props.language].mr : "Mr."}</option>
        <option value="Mrs.">{props.language ? props.translation[props.language].mrs : "Mrs."}</option>
        {props.language === "english" && <option value="Ms.">Ms.</option>}
      </select>
    </div>
  )
}

export const LanguageInput = (props) => {
  return (
    <div className={"select-wrapper " + props.className}>
      <select disabled={props.disabled} value={props.value} onChange={props.handleChange} name={props.name} onFocus={props.OnFocus} onBlur={props.OnBlur}>
        <option value="english">English</option>
        <option value="french">French</option>
        <option value="german">German</option>
      </select>
    </div>
  )
}

export const AdminInput = (props) => {
  return (
    <div className={"select-wrapper " + props.className}>
      <select disabled={props.disabled} value={props.value} onChange={props.handleChange} name={props.name}>
        <option value="">Choose user type</option>
        <option value="manager">Event Manager</option>
        <option value="reception">Receptionist</option>
      </select>
    </div>
  )
}

export const EmailUserInput = (props) => {
  return (
    <div className={"select-wrapper " + props.className}>
      <select disabled={props.disabled} value={props.value} onChange={props.handleChange} name={props.name}>
        <option value="">Choose user</option>
        {props.users.map((item,index) => {
          return (
            <option key={index} value={item}>{item}</option>
          )
        })}
      </select>
    </div>
  )
}

export const SendEmailInput = (props) => {
  return (
    <div className={"select-wrapper " + props.className}>
      <select disabled={props.disabled} value={props.value} onChange={props.handleChange} name={props.name}>
        <option value="">Choose recipients</option>
        <option value="confirmed">All confirmed participants</option>
        {!props.waitingEmpty && <option value="waiting">All waiting list users</option>}
        {/* <option value="individual">Individual participant</option> */}
      </select>
    </div>
  )
}

export const SendTypeInput = (props) => {
  return (
    <div className={"select-wrapper " + props.className}>
      <select disabled={props.disabled} value={props.value} onChange={props.handleChange} name={props.name}>
        <option value="">Choose send method</option>
        <option value="web">Send from this application (directly)</option>
        <option value="outlook">Send from my email (Outlook)</option>
        <option value="email">Send from my email (Apple, Gmail etc)</option>
      </select>
    </div>
  )
}

export const CheckBoxInput = (props) => {
  return (
    <div className={props.className}>
      <div>
        <input type="checkbox" checked={props.checked} disabled={props.disabled} onChange={props.handleChange} name={props.name} />
        {props.label && <span className="showMobile label">{props.label}</span>}
      </div>
      <span>{props.title}{props.required && !props.checked && <span className="requiredText bold">*</span>}</span>
    </div>
  )
}

// export const CheckBoxArrayInput = (props) => {
//   return (
//     Object.values(props.events).map((item,index) => (
//       <div key={index}>
//         {/* <input type="checkbox" checked={Object.keys(props.eventID).indexOf(item.eventID) > -1} disabled={props.disabled} onChange={props.handleChange} name={props.name} /> */}
//         <span className="label">{item.eventName}</span>
//       </div>
//     ))
//   )
// }

export const FormInputGroup = (props) => {
  return (
    <div className="inputGroup">
      {props.type !== "checkbox" && <div><span>{props.title}</span>{props.required && (props.name === "password" ? props.value.length < 6 : !props.value) && <span className="requiredText bold">*</span>}</div>}
      {props.type === "textarea" && <TextAreaInput {...props} />}
      {props.type === "date" && <DateInput {...props} />}
      {props.type === "number" && <NumberInput {...props} />}
      {props.type === "text" && <TextInput {...props} />}
      {props.type === "email" && <EmailInput {...props} />}
      {props.type === "file" && <FileInput {...props} />}
      {props.type === "time" && <TimeInput {...props} />}
      {props.type === "duration" && <DurationInput {...props} />}
      {/* {props.type === "eventID" && <CheckBoxArrayInput {...props} />} */}
      {props.type === "checkbox" && <CheckBoxInput {...props} />
      }
      {props.type === "sendEmail" && <SendEmailInput {...props} />}
      {props.type === "sendType" && <SendTypeInput {...props} />}
      {props.type === "emailUser" && <EmailUserInput {...props} />}
      {props.type === "title" && <TitleInput {...props} />}
      {props.type === "language" && <LanguageInput {...props} />}
      {props.type === "country" && <CountryInput {...props} />}
      {props.type === "timezone" && <TimeZoneInput {...props} />}
      {props.type === "admin" && <AdminInput {...props} />}
    </div>
  )
}
