import "./../App.css";
export const Modal = ({state,HideModal}) => {
  return (
    <div className="modal">
      <div className="modalInner">
        {state.modalTitle && <h3>{state.modalTitle}</h3>}
        {state.modalContentTwo && <p>{state.modalContentTwo}</p>}
        <p>{state.modalContent}</p>
        <button onClick={() => HideModal(true)}>OK</button>
        {state.modalType === "confirm" && <button onClick={() => HideModal(false)}>Cancel</button>}
      </div>
    </div>
  )
}
