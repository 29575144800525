import "./../App.css";
import {FormInputGroup} from "./../components/FormInputGroup.js";
import {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import {db,} from ".././firebase.js";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import {MinsToStr,StrToMins} from "./../Methods.js";

export const SelfCancelUserPage = ({state,setState,ShowModal,handleChange,translation}) => {

  const {eventID,activityGroupID,activityID,userID} = useParams();
  const [show, setShow] = useState();
  const [showWarning, setShowWarning] = useState();
  const [finished, setFinished] = useState();
  const eventItem = state.events[eventID];
  const activityGroupItem = state.activityGroups[activityGroupID];
  const activityItem = state.activities[activityID];
  const endTime = activityItem ? MinsToStr(StrToMins(activityItem.duration) + StrToMins(activityItem.startTime)) : null;
  const docRef = doc(db, "users", userID);

  useEffect(() => {
    ViewCancelUserPage();
  },[]);

  const ViewCancelUserPage = async() => {
    if (!eventItem || !activityGroupItem || !activityItem) {
      // console.log("No eventItem etc");
      setShowWarning(true);
      return;
    }
    const userItem = await getDoc(docRef);
    if (userItem.exists() && userItem.data().userType !== "cancelled") {
      setState((prevState) => ({
        ...prevState,
        title: userItem.data().title,
        firstName: userItem.data().firstName,
        lastName: userItem.data().lastName,
        email: userItem.data().email,
        userType: userItem.data().userType,
        emailConfirmation: userItem.data().emailConfirmation
      }));
      setShow(true);
    } else {
      // console.log("No such document!");
      setShowWarning(true);
      return;
    }
  }

  const Cancel = async () => {
    const timeStamp = Date.now();
    await updateDoc(docRef, {
      "userType": "cancelled",
      "lastUpdated": timeStamp,
      "emailConfirmation": state.emailConfirmation,
      "selfReg": true
    });
    ShowModal("alert", translation[eventItem.language].cancelledThankYou);
    setFinished(true);
  }

  return (
    <div>
      {show && <section>
        {!finished &&
          <div>
            <h1>{translation[eventItem.language].cancelRegistration}</h1>
            <h3>{activityItem.activityName}</h3>
            <p>{activityItem.startDate.split("-").reverse().join("-")} | {activityItem.startTime}{translation[eventItem.language].hrs} - {endTime}{translation[eventItem.language].hrs} ({activityItem.duration.replace(":","h")})</p>
            <p>{activityItem.activityLocation}{activityItem.activityComment && activityItem.activityLocation && " | " }{activityItem.activityComment}</p>
            <form autoComplete="off" onSubmit={(e) => {e.preventDefault(); Cancel()}}>
              {eventItem.titleField && <FormInputGroup translation={translation} disabled={true} type="title" title={translation[eventItem.language].title} language={eventItem.language} value={state.title} handleChange={handleChange} name="title" />}
              <FormInputGroup translation={translation} disabled={true} title={translation[eventItem.language].firstName} type="text" value={state.firstName} handleChange={handleChange} name="firstName" />
              <FormInputGroup translation={translation} disabled={true} title={translation[eventItem.language].lastName}  type="text" value={state.lastName} handleChange={handleChange} name="lastName" />
              <FormInputGroup translation={translation} disabled={true} title={translation[eventItem.language].emailAddress} type="email" value={state.email} handleChange={handleChange} name="email" />
              <span className="gdpr">{translation[eventItem.language].gdprText}</span>
              <FormInputGroup translation={translation} title={translation[eventItem.language].iWantCancelConfirm} type="checkbox" className="checkboxGroup" checked={state.emailConfirmation} handleChange={handleChange} name="emailConfirmation" />
              <button type="submit">{translation[eventItem.language].confirmCancellation}</button>
            </form>
          </div>
        }
        {finished && <h2>{translation[eventItem.language].cancelComplete}</h2>}
      </section>}
      {showWarning && <section>
        <h1>{translation[eventItem ? eventItem.language : "english"].pageNotFound}</h1>
        <p>{translation[eventItem ? eventItem.language : "english"].youAreNotRegistered}</p>
      </section>}
    </div>
  )
}
