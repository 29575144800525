import "./../App.css";
import {ConvertTime} from "./../Methods.js";
import { useNavigate } from "react-router-dom";

export const AdminTable = ({state,eventID}) => {
  const navigate = useNavigate();
  return (
    <table cellPadding="0" cellSpacing="0">
      <thead>
        <tr className="bold">
          <td>Username (email)</td>
          <td>Password</td>
          <td>User type</td>
          {/* <td>Events</td> */}
          <td>Last updated</td>
          <td>Actions</td>
        </tr>
      </thead>
      <tbody>
        {/* {Object.entries(users) */}
        {Object.entries(state.users).filter(item => item[1].eventID && item[1].eventID.toString() === eventID.toString() && (item[1].role === "reception" || item[1].role === "manager"))
          .map((item,index) => (
            <tr className="user" key={index}>
              <td className="bold">{item[1].email}</td>
              <td className="bold">{item[1].password}</td>
              <td>{item[1].role === "reception" ? "Receptionist" : "Event manager"}</td>
              {/* <td>{events[item[1].eventID].eventName}</td> */}
              <td className="bold">{ConvertTime(item[1].lastUpdated)}</td>
              <td>
                <button className="secondary" onClick={() => navigate("/edit-admin/" + item[1].userID)}>Edit</button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  )
}
