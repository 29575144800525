import "./../App.css";
import React, { useEffect,useState } from 'react';
import QRCode from "qrcode";

export const QR = ({passedURL}) => {

  const [url, setURL] = useState("");
  const [showImage, setShowImage] = useState("");
  var opts = {
    errorCorrectionLevel: 'H',
    type: 'image/png',
    quality: 0.3,
    margin: 1
  }

  const loadQR = () => {
    QRCode.toDataURL(window.location.origin + '/' + passedURL, opts, function (err, url) {
      if (err) throw err
      setURL(url)
      setShowImage(true)
    })
  }

  useEffect(() => {
    loadQR();
  },[])

  if (showImage) return (
    <img src={url} alt="qrImage" className="qrImage" />
  )
  return null;
}
