import "./../App.css";
import {FormInputGroup} from "./../components/FormInputGroup.js";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {db,storage} from './../firebase';
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {FirestoreAutoId} from "./../Methods.js";
import { zonedTimeToUtc } from 'date-fns-tz';

export const NewActivityPage = ({state,ShowModal,setState,handleChange,Back,ResetFields}) => {

  useEffect(() => {
    ViewNewActivityPage(eventID, activityGroupID);
    setEventStartDate(state.events[eventID].startDate);
    setEventEndDate(state.events[eventID].endDate);
  },[]);

  const {eventID, activityGroupID} = useParams();
  const [eventStartDate, setEventStartDate] = useState();
  const [eventEndDate, setEventEndDate] = useState();
  const navigate = useNavigate();
  const eventItem = state.events[eventID];
  const activityGroupItem = state.activityGroups[activityGroupID];
  const [activityID,setActivityID] = useState();
  const dateFormat = new Date((state.startDate || eventItem.startDate) + "T" + (state.startTime || "12:00"));
  const timeStampStartDate = zonedTimeToUtc(dateFormat, eventItem.timeZone);
  const timeStamp = Date.parse(timeStampStartDate);
  const reminderTimeOne = timeStamp - (60*60*1000*24);
  const reminderTimeTwo = timeStamp - (60*60*1000);
  const reminderTimeOneLocal = new Date(reminderTimeOne);
  const reminderTimeTwoLocal = new Date(reminderTimeTwo);
  const [file, setFile] = useState("");

  // useEffect(() => {
  //   if (!file) return;
  //   const path = `/activity-pdfs/${eventID}/${activityGroupID}/${activityID}/${file.name}`;
  //   const storageRef = ref(storage, path);
  //   // 'file' comes from the Blob or File API
  //   uploadBytes(storageRef, file).then((snapshot) => {
  //     // Upload completed successfully, now we can get the download URL
  //     getDownloadURL(snapshot.ref).then((downloadURL) => {
  //       setURL(downloadURL);
  //     });
  //   });
  // },[file]);

  const HandleImageAsFile = (e) => {
    if (e.target.files[0]) setFile(e.target.files[0]);
  }

  const FieldCheck = () => {
    if (!state.activityName || !state.activityLocation || !state.startDate || !state.duration || !state.capacity || !state.capacityWait) ShowModal("alert", "Please complete all required fields");
    // else if (Date.parse(state.startDate + "T" + state.startTime) < Date.now()) ShowModal("alert", "Start time cannot be in the past");
    else AddActivity(eventID,activityGroupID);
  }

  const ViewNewActivityPage = (eventID,activityGroupID) => {
    setActivityID(FirestoreAutoId());
    setState((prevState) => ({
      ...prevState,
      activityGroupName: activityGroupItem.activityGroupName,
      eventName: eventItem.eventName,
      startDate: eventItem.startDate,
      startTime: "12:00",
      duration: "01:00",
      selfReg: true,
      reminderTimeOne: reminderTimeOne,
      reminderTimeTwo: reminderTimeTwo,
      sendReminder: true
    }));
  }

  const AddActivity = async (eventID,activityGroupID) => {
    const activityRef = doc(db, "activities", activityID);
    setDoc(activityRef,{
      "activityName":state.activityName,
      "activityComment":state.activityComment,
      "activityLocation":state.activityLocation,
      "calendarNote":state.calendarNote,
      "eventID":eventID,
      "activityGroupID":activityGroupID,
      "activityID":activityID,
      "startDate":state.startDate,
      "startTime":state.startTime,
      "reminderTimeOne": state.sendReminder ? reminderTimeOne : null,
      "reminderTimeTwo": state.sendReminder ? reminderTimeTwo : null,
      "duration":state.duration,
      "capacity":state.capacity,
      "capacityWait":state.capacityWait,
      "selfReg": state.selfReg,
      "open":true,
      "ticketsLeft":Number(state.capacity),
      "sendReminder": state.sendReminder,
      "pdf": file ? `${file.name}` : null
    })
    if (file) {
      const path = `/activity-pdfs/${eventID}/${activityGroupID}/${activityID}/${file.name}`;
      const storageRef = ref(storage, path);
      // 'file' comes from the Blob or File API
      uploadBytes(storageRef, file).then((snapshot) => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          // setURL(downloadURL);
        });
      });
    }
    ShowModal("alert", "Activity added");
    navigate("/");
    ResetFields();
  }

  if (state.role !=="super" && state.role !== "manager") return (
    <div>
      <button className="secondary" onClick={() => Back(true)}>Back</button>
      <section>
        <h1>You cannot access this page</h1>
      </section>
    </div>
  )
  return (
    <div>
      <button className="secondary" onClick={() => Back(true)}>Back</button>
      <section>
        <h1>New activity</h1>
        <form onSubmit={(e) => {e.preventDefault(); FieldCheck()}}>
          <p><small>PDF - About Activity</small></p>
          <div className="fileInput">
            {/* {url && <span>PDF uploaded</span>} */}
            <FormInputGroup type="file" text="pdf" title="" handleChange={HandleImageAsFile} name="activityPDF" />
          </div>
          <FormInputGroup disabled={true} type="textarea" value={state.eventName} title="Event" />
          <FormInputGroup disabled={true} type="textarea" value={state.activityGroupName} title="Activity group" />
          <FormInputGroup required={true} className={!state.activityName ? "required" : null} type="textarea" handleChange={handleChange} name="activityName" value={state.activityName} title="Activity name" />
          <FormInputGroup required={true} className={!state.activityLocation ? "required" : null} type="textarea" handleChange={handleChange} name="activityLocation" value={state.activityLocation} title="Activity location" />
          <FormInputGroup type="textarea" handleChange={handleChange} name="activityComment" value={state.activityComment} title="Activity comment" />
          <FormInputGroup type="textarea" handleChange={handleChange} value={state.calendarNote} title="Email calendar note" name="calendarNote" />
          <FormInputGroup required={true} type="date" className={!state.startDate ? "required" : null} min={eventStartDate} max={eventEndDate} handleChange={handleChange} name="startDate" value={state.startDate} title="Date" />
          <FormInputGroup required={true} type="time" className={!state.startTime ? "required" : null} min="07:00" max="23:00" handleChange={handleChange} name="startTime" value={state.startTime} title={"Start time (" + state.events[eventID].timeZone + ")"} />
          <FormInputGroup type="duration" className={!state.duration ? "required" : null} handleChange={handleChange} name="duration" value={state.duration} title="Duration" />
          <FormInputGroup required={true} type="number" className={!state.capacity ? "required" : null} handleChange={handleChange} name="capacity" value={state.capacity} title="Capacity" />
          <FormInputGroup required={true} type="number" className={!state.capacityWait ? "required" : null} handleChange={handleChange} name="capacityWait" value={state.capacityWait} title="Waiting list capacity" />
          <div className="card settings">
            <h5>Settings</h5>
            <div className="inner">
              <FormInputGroup title="Self-registration open" type="checkbox" className="checkboxGroup" checked={state.selfReg} handleChange={handleChange} name="selfReg" />
              <FormInputGroup title="Send reminder emails" type="checkbox" className="checkboxGroup" checked={state.sendReminder} handleChange={handleChange} name="sendReminder" />
              {state.sendReminder && <p><small>First reminder (24hrs before): {reminderTimeOneLocal.toString()}</small></p>}
              {state.sendReminder && <p><small>Second reminder (1hr before): {reminderTimeTwoLocal.toString()}</small></p>}
            </div>
          </div>
          <button type="submit">Create new activity</button>
        </form>
      </section>
    </div>
  )
}
