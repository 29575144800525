import "./../App.css";

export const NotFound = ({Back}) => {
  return (
    <div>
      {/*<button className="secondary" onClick={() => Back(true)}>Back</button>*/}
      <section>
        <h1>Page not found</h1>
        {/*<p>You may need to login first</p>*/}
      </section>
    </div>
  )
}
