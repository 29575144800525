import React, { useEffect, useState } from 'react';
import "./../App.css";
import {FormInputGroup, CheckBoxInput} from "./../components/FormInputGroup.js";
import { useNavigate } from "react-router-dom";
import {FirestoreAutoId} from "./../Methods.js";
import {db, storage} from './../firebase';
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const NewEventPage = ({state,ShowModal,ResetFields,handleChange,Back}) => {

  const navigate = useNavigate();
  const [url, setURL] = useState("");
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [eventID, setEventID] = useState("");

  useEffect(() => {
    ViewNewEventPage();
  },[]);

  const ViewNewEventPage = () => {
    setEventID(FirestoreAutoId());
  }

  const FieldCheck = () => {
    const startDateComp = new Date(state.startDate);
    const endDateComp = new Date(state.endDate);
    if (!state.eventName || !state.startDate || !state.endDate || !state.timeZone) ShowModal("alert", "Please complete required fields");
    else if (startDateComp > endDateComp) ShowModal("alert", "Event end date cannot be before start date");
    else AddEvent();
  }

  const HandleImageAsFile = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]); 
      let str = e.target.files[0].type;
      setFileName(eventID + '.' + str.replace(/(.*)\//g, ''));
    }
  }

  const AddEvent = async () => {
    const eventRef = doc(db, "events", eventID);
    setDoc(eventRef,{
      "eventName": state.eventName,
      "startDate": state.startDate,
      "endDate": state.endDate,
      "eventID": eventID,
      "countryField": state.countryField || false,
      "institutionField": state.institutionField || false,
      "commentField": state.commentField || false,
      "titleField": state.titleField || false,
      "addressField": state.addressField || false,
      "phoneField": state.phoneField || false,
      "countryRequired": state.countryRequired || false,
      "institutionRequired": state.institutionRequired || false,
      "commentRequired": state.commentRequired || false,
      "titleRequired": state.titleRequired || false,
      "addressRequired": state.addressRequired || false,
      "phoneRequired": state.phoneRequired || false,
      "eventLogo": file ? `${fileName}` : state.eventLogo || null,
      "timeZone": state.timeZone,
      "language": state.language,
      "selfReg": state.selfReg
    })
    // const userRef = doc(db, "users", state.loggedInUserID);
    // const userDoc = await getDoc(userRef);
    // if (userDoc.exists() && userDoc.data().role === "manager") {
    //   let eventIDObject;
    //   eventIDObject = userDoc.data().eventIDs || {};
    //   eventIDObject[eventID] = true;
    //   setDoc(userRef,{
    //     "eventID": eventIDObject
    //   })
    // }
    if (file) {
      const path = `/event-logos/${eventID}/${fileName}`;
      const storageRef = ref(storage, path);
      // 'file' comes from the Blob or File API
      uploadBytes(storageRef, file).then((snapshot) => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          setURL(downloadURL);
        });
      });
    }
    ShowModal("alert", "Event added");
    navigate("/");
    ResetFields();
  }

  // if (state.role !=="super" && state.role !=="manager") return (
    if (state.role !=="super") return (
    <div>
      <button className="secondary" onClick={() => Back(true)}>Back</button>
      <section>
        <h1>You cannot access this page</h1>
      </section>
    </div>
  )
  return (
    <div>
      <button className="secondary" onClick={() => Back(true)}>Back</button>
      <section>
        <h1>New event</h1>
        <form onSubmit={(e) => {e.preventDefault(); FieldCheck()}}>
          {<div className="fileInput">
            {url && <img src={url} alt="Event logo" className="eventLogo" />}
            <FormInputGroup type="file" text="image" title="Event logo" handleChange={HandleImageAsFile} name="eventLogo" />
          </div>}
          <FormInputGroup type="textarea" title="Name" required={true} className={!state.eventName ? "required" : null} value={state.eventName} handleChange={handleChange} name="eventName" />
          <FormInputGroup type="date" title="Event start date" required={true} className={!state.startDate ? "required" : null} value={state.startDate} handleChange={handleChange} name="startDate" />
          <FormInputGroup type="date" title="Event end date" required={true} className={!state.endDate ? "required" : null} value={state.endDate} handleChange={handleChange} name="endDate" />
          <FormInputGroup type="timezone" title="Event timezone - locked once you create an activity!" required={true} className={!state.timeZone ? "required" : null} value={state.timeZone} handleChange={handleChange} name="timeZone" />
          <FormInputGroup type="language" title="Language - self-registration only" required={true} className={!state.language ? "required" : null} value={state.language} handleChange={handleChange} name="language" />
          <div className='card settings'>
            <h5>Settings</h5>
            <div className='inner'>
              <FormInputGroup type="checkbox" className="checkboxGroup" title="Self-registration open (overrides individual activity)" checked={state.selfReg} handleChange={handleChange} name="selfReg" />
            </div>
          </div>
          <h3>Set optional registration fields</h3>
          <table cellPadding="0" cellSpacing="0">
            <thead>
              <tr className="bold">
                <td>Field name</td>
                <td>Add field</td>
                <td>Required</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Participant title</td>
                <td><CheckBoxInput checked={state.titleField} handleChange={handleChange} name="titleField" label="Add field" /></td>
                <td><CheckBoxInput disabled={!state.titleField} checked={state.titleRequired} handleChange={handleChange} name="titleRequired" label="Required" /></td>
              </tr>
              <tr>
                <td>Participant phone</td>
                <td><CheckBoxInput checked={state.phoneField} handleChange={handleChange} name="phoneField" label="Add field" /></td>
                <td><CheckBoxInput disabled={!state.phoneField} checked={state.phoneRequired} handleChange={handleChange} name="phoneRequired" label="Required" /></td>
              </tr>
              <tr>
                <td>Participant institution</td>
                <td><CheckBoxInput checked={state.institutionField} handleChange={handleChange} name="institutionField" label="Add field" /></td>
                <td><CheckBoxInput disabled={!state.institutionField} checked={state.institutionRequired} handleChange={handleChange} name="institutionRequired" label="Required" /></td>
              </tr>
              <tr>
                <td>Participant address</td>
                <td><CheckBoxInput checked={state.addressField} handleChange={handleChange} name="addressField" label="Add field" /></td>
                <td><CheckBoxInput disabled={!state.addressField} checked={state.addressRequired} handleChange={handleChange} name="addressRequired" label="Required" /></td>
              </tr>
              <tr>
                <td>Participant country</td>
                <td><CheckBoxInput checked={state.countryField} handleChange={handleChange} name="countryField" label="Add field" /></td>
                <td><CheckBoxInput disabled={!state.countryField} checked={state.countryRequired} handleChange={handleChange} name="countryRequired" label="Required" /></td>
              </tr>
              <tr>
                <td>Participant comment</td>
                <td><CheckBoxInput checked={state.commentField} handleChange={handleChange} name="commentField" label="Add field" /></td>
                <td><CheckBoxInput disabled={!state.commentField} checked={state.commentRequired} handleChange={handleChange} name="commentRequired" label="Required" /></td>
              </tr>
            </tbody>
          </table>
          <button type="submit">Create new event</button>
        </form>
      </section>
    </div>
  )
}
