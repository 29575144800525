import "./../App.css";
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import {db, storage} from './../firebase';
import { getDownloadURL, ref } from "firebase/storage";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import {Table} from "./../components/Table.js";
import {CompareTimeStamp,MinsToStr,StrToMins} from "./../Methods.js";
import { QrReader } from 'react-qr-reader';
import BigTick from "./../images/bigTick.png";
import {PDF} from './../components/PDF.js';

export const ActivityOverviewPage = ({state,setState,Back,changeOrder}) => {

  useEffect(() => {
    ViewActivityPage(eventID, activityGroupID,activityID);
  },[]);

  const navigate = useNavigate();
  const {eventID,activityGroupID,activityID} = useParams();
  const eventItem = state.events[eventID];
  const activityGroupItem = state.activityGroups[activityGroupID];
  const activityItem = state.activities[activityID];
  const [showCancels, setshowCancels] = useState(false);
  const [status, setStatus] = useState("");
  const [showStatus, setShowStatus] = useState(false);
  const [statusError, setStatusError] = useState("red");
  const [showQRPanel, setshowQRPanel] = useState(false);
  const [showPermis, setShowPermis] = useState(false);
  const registeredUsers = Object.values(state.users).filter((item) => item.activityID === activityID && item.userType === "confirmed").sort(CompareTimeStamp);
  const waitingUsers = Object.values(state.users).filter((item) => item.activityID === activityID && item.userType === "waiting").sort(CompareTimeStamp);
  const cancelledUsers = Object.values(state.users).filter((item) => item.activityID === activityID && item.userType === "cancelled").sort(CompareTimeStamp);
  const registeredEmailUsers = Object.values(state.users).filter((item) => item.activityID === activityID && item.userType === "confirmed" && item.emailConfirmation);
  const registeredNum = registeredUsers.length || 0;
  const registeredEmailNum = registeredEmailUsers.length || 0;
  const waitingListNum = waitingUsers.length || 0;
  const checkedInWait = waitingUsers.filter(item => item.checkedIn).length || 0;
  const checkedInReg = registeredUsers.filter(item => item.checkedIn).length || 0;
  const checkedInNum = activityItem && checkedInWait + checkedInReg;
  const endTime = activityItem && MinsToStr(StrToMins(activityItem.duration) + StrToMins(activityItem.startTime));
  let canCheck = true;
  const [showPDF,setShowPDF] = useState(false);
  const [url, setURL] = useState("");

  const CheckInUser = async (passedUser, qr) => {
    const usersRef = doc(db, 'users', passedUser.userID);
    const timeStamp = Date.now();
    updateDoc(usersRef,{
      "checkedIn": qr ? true : passedUser.checkedIn ? false : true,
      "lastUpdated": timeStamp
    })
  }

  const CheckInQR = async (passedString) => {
    if (!canCheck) return;
    if (passedString.length !== 80) {
      ShowQRStatus("","Error: QR code is not recognised!", "red", 3000);
      return;
    }
    canCheck = false;
    const eventString = passedString.slice(0,20);
    const groupString = passedString.slice(20,40);
    const activityString = passedString.slice(40,60);
    const userString = passedString.slice(60,80);
    const docRef = doc(db, "users", userString);
    const docSnap = await getDoc(docRef);
    const user = docSnap.data();
    if (docSnap.exists()) {
      if (eventString !== eventID || groupString !== activityGroupID || activityString !== activityID) ShowQRStatus(user,"Error: QR code is not for this activity!", "amber", 3000);
      else if (!user || user.userType === "cancelled") ShowQRStatus(user,"Error: User has cancelled or does not exist in database!", "amber", 3000);
      else if (user && user.checkedIn) ShowQRStatus(user,"Error: User has already checked in!", "amber", 3000);
      else if (user && user.userType !== "cancelled") ShowQRStatus(user,user.firstName + " checked in!", "green", 3000);
    } else return;
  }

  const ShowQRStatus = (user,text,result,timing) => {
    setShowStatus(true);
    setStatus(text);
    setStatusError(result);
    if (result === "green") CheckInUser(user, true);
    setTimeout(() => {
      setShowStatus(false);
      canCheck = true;
    }, timing);
  }

  const ViewActivityPage = (eventID,activityGroupID,activityID) => {
    if (!eventItem || !activityGroupItem || !activityItem) return;
    setState((prevState) => ({
      ...prevState,
      activityName: activityItem.activityName,
      activityComment: activityItem.activityComment,
      activityLocation: activityItem.activityLocation,
      calendarNote: activityItem.calendarNote,
      activityGroupName: activityGroupItem.activityGroupName,
      eventName: eventItem.eventName,
      startDate: activityItem.startDate,
      startTime: activityItem.startTime,
      // reminderTimeOne: activityItem.reminderTimeOne,
      // reminderTimeTwo: activityItem.reminderTimeTwo,
      duration: activityItem.duration,
      capacity: activityItem.capacity,
      capacityWait: activityItem.capacityWait,
      activityGroupID: activityItem.activityGroupID,
      open: activityItem.open,
      countryField: eventItem.countryField,
      institutionField: eventItem.institutionField,
      commentField: eventItem.commentField,
      titleField: eventItem.titleField,
      addressField: eventItem.addressField,
      phoneField: eventItem.phoneField,
      countryRequired: eventItem.countryRequired,
      institutionRequired: eventItem.institutionRequired,
      commentRequired: eventItem.commentRequired,
      titleRequired: eventItem.titleRequired,
      addressRequired: eventItem.addressRequired,
      phoneRequired: eventItem.phoneRequired
    }));
  }

  const ViewPDF = () => {
    if (activityItem.pdf) {
      window.scrollTo(0, 0);
      document.body.classList.add('modal-open');
      const pdfRef = ref(storage, 'activity-pdfs/' + eventID + '/' + activityItem.activityGroupID + '/' + activityItem.activityID + '/' + activityItem.pdf);
      // Get the download URL
      getDownloadURL(pdfRef)
        .then((url) => {
          setURL(url);
          setShowPDF(true);
        })
        .catch((error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/object-not-found':
              // File doesn't exist
              break;
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;

            // ...

            case 'storage/unknown':
              // Unknown error occurred, inspect the server response
              break;
          }
        });
    }
  }

  const closePDF = () => {
    document.body.classList.remove('modal-open');
    setShowPDF(false)
  }

  if (activityItem) return (
    <div>
      <div className="justify justifyMobile">
        <button className="secondary" onClick={() => Back(true)}>Back</button>
        <button onClick={() => navigate("/edit-activity/" + eventID + "/" + activityGroupID + "/" + activityID)}>Edit activity</button>
      </div>
      <section>
        <div className={!activityItem.open ? "card overview red" : activityItem.ticketsLeft > 0 ? "card overview green" : "card overview amber"}>
          <div className="justify">
            <h1>{activityItem.activityName}</h1>
            <div className="justifyMobile showMobile">
              {/*<button onClick={() => ReminderEmail(eventItem.eventName, eventID, activityGroupID, activityItem)}>Reminder email test</button>*/}
              {activityItem.open && state.role === "reception" && <button className={activityItem.ticketsLeft > 0 ? "darken half green" : "darken half amber"} onClick={() => navigate("/register-activity/" + eventID + "/" + activityGroupID + "/" + activityID)}>{activityItem.ticketsLeft < 1 ? "Join waiting list" : "Register"}</button>}
              {activityItem.open && state.role === "reception" && <button className="half edit" onClick={() => setshowQRPanel(true)}>QR Check-in</button>}
            </div>
            <div className="justifyMobile">
              {/* {(state.role ==="super" || state.role === "manager") && <button className="half edit" onClick={() => navigate("/edit-activity/" + eventID + "/" + activityGroupID + "/" + activityID)}>Edit activity</button>} */}
                {/*<button onClick={() => ReminderEmail(eventItem.eventName, eventID, activityGroupID, activityItem)}>Reminder email test</button>*/}
              {activityItem.open && state.role === "reception" && <button className={activityItem.ticketsLeft > 0 ? "darken half showDesktop green" : "darken half showDesktop amber"} onClick={() => navigate("/register-activity/" + eventID + "/" + activityGroupID + "/" + activityID)}>{activityItem.ticketsLeft < 1 ? "Join waiting list" : "Register"}</button>}
              {registeredNum > 0 && activityItem.open && state.role === "reception" && <button className="half edit showDesktop" onClick={() => setshowQRPanel(true)}>QR Check-in</button>}
              {registeredEmailNum > 0 && state.role === "reception" && <button className="half edit" onClick={() => navigate("/email-participants/" + eventID + "/" + activityGroupID + "/" + activityID)}>Email participants</button>}
              {activityItem.pdf && <button className="half" onClick={() => ViewPDF()}>About activity</button>}
            </div>
          </div>
          <span className="comment"><i>{state.activityLocation}{state.activityComment && state.activityLocation && " | " }{state.activityComment}</i></span>
          <div className="justify">
            <h4>{activityItem.startDate.split("-").reverse().join("-")} | {activityItem.startTime}hrs - {endTime}hrs ({activityItem.duration.replace(":","h")})</h4>
            <div className="stats">
              <span className="bold">Registrations: {registeredNum} / {activityItem.capacity}</span>
              <span className="bold">Waiting list: {waitingListNum} / {activityItem.capacityWait || 0}</span>
              <span className="bold">Participating: {checkedInNum} / {activityItem.capacity}</span>
            </div>
          </div>
          {/*activityItem.selfReg && activityItem.open && <a href={selfRegLink} target="_blank" rel="noreferrer" rel="noreferrer" className="self">Self-registration</a>*/}
        </div>
      </section>
      {showQRPanel && <div className="qrHolder">
        <h2>QR ticket reader</h2>
        <div className="qrHeader">
         <p className="white">Show QR code to the camera</p>
         <button onClick={() => setshowQRPanel(false) }>CLOSE</button>
        </div>
        {showStatus && <p className={statusError === "red" ? "qrStatus red" : statusError === "amber" ? "qrStatus amber" : "qrStatus"}>{status}</p>}
        {showStatus && statusError === "green" && <img src={BigTick} className="qrTick" />}
        {showPermis && <p className="qrStatus qrStatusTop red">You need to grant permission to use the camera. Please check your browser settings</p>}
        <QrReader
          constraints={{ facingMode: 'environment' }}
          onResult={(result, error) => {
            if (!!result) {
              CheckInQR(result?.text);
            }
            if (!!error) {
              // let errorMessage = error.toString();
              if (error.toString().includes("NotAllowedError")) setShowPermis(true);
            }
          }}
          videoId="videoId"
          className="videoContainer"
        />
      </div>}
      <section>
        <h2>Registered participants</h2>
        {registeredUsers.length > 0 ? <Table changeOrder={changeOrder} users={registeredUsers} state={state} eventItem={eventItem} activityGroupItem={activityGroupItem} activityItem={activityItem} CheckInUser={CheckInUser} userType="confirmed" /> : <p>No registrations</p>}
      </section>
      {waitingUsers.length > 0 && <section>
        <h2>Waiting list</h2>
        <Table changeOrder={changeOrder} users={waitingUsers} state={state} eventItem={eventItem} activityGroupItem={activityGroupItem} activityItem={activityItem} CheckInUser={CheckInUser} userType="waiting" />
      </section>}
      {cancelledUsers.length > 0 && <section>
        <button onClick={() => setshowCancels(!showCancels)} className="showHide"><div className="one"><h2>Cancellations</h2></div><div className="two"><div>{showCancels ? "Hide" : "Show"}</div></div></button>
        {!showCancels && <hr/>}
        {showCancels && <Table changeOrder={changeOrder} state={state} users={cancelledUsers} eventItem={eventItem} activityGroupItem={activityGroupItem} activityItem={activityItem} CheckInUser={CheckInUser} userType="cancelled" />}
      </section>}
      {showPDF && 
        <div style={{position:'absolute',left:0,right:0,top:0}}>
          <button className="modalHeader" onClick={() => closePDF()}>Close</button>
          <PDF url={url} />
        </div>
      }
    </div>
  )
  else return (
    <div>
      <section>
        <div>
          <h1>Page not found</h1>
          <p>Activity does not exist</p>
        </div>
      </section>
    </div>
  );
}
