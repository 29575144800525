export const Capitalize = (word) => {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}

export const ConvertTime = (passed) => {
  var date = new Date(passed);
  return date.toString().substring(0,21);
}

export const CompareStartDate = (a,b) => {
  if ( a.startDate > b.startDate ) {
    return -1;
  }
  if ( a.startDate < b.startDate ) {
    return 1;
  }
  return 0;
}

export const CompareStartTime = (a,b) => {
  if ( a.startTime < b.startTime ) {
    return -1;
  }
  if ( a.startTime > b.startTime ) {
    return 1;
  }
  return 0;
}

export const CompareStartDateThenTime = (a,b) => {
  if (a.startDate < b.startDate) {
    return -1;
  } else if (a.startDate > b.startDate) {
      return 1;
  } else { // keyA == keyB
      if (a.startTime < b.startTime) {
          return -1;
      } else if (a.startTime > b.startTime) {
          return 1;
      } else {
          return 0;
      }
  }
}

export const CompareActivityName = (a,b) => {
  if ( a.activityName < b.activityName ) {
    return -1;
  }
  if ( a.activityName > b.activityName ) {
    return 1;
  }
  return 0;
}

export const CompareActivityGroupName = (a,b) => {
  if ( a.activityGroupName < b.activityGroupName ) {
    return -1;
  }
  if ( a.activityGroupName > b.activityGroupName ) {
    return 1;
  }
  return 0;
}

export const CompareTimeStamp = (a,b) => {
  if ( a.timeStamp < b.timeStamp ) {
    return -1;
  }
  if ( a.timeStamp > b.timeStamp ) {
    return 1;
  }
  return 0;
}

export const StrToMins = (t) => {
  const s = t.split(":");
  return Number(s[0]) * 60 + Number(s[1]);
}

export const MinsToStr = (t) => {
  const tr = Math.trunc(t / 60) < 10 ? ("0" + Math.trunc(t / 60)) : Math.trunc(t / 60);
  return tr+':'+('00' + t % 60).slice(-2);
}

export const FirestoreAutoId = () => {
  const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  let autoId = ''

  for (let i = 0; i < 20; i++) {
    autoId += CHARS.charAt(
      Math.floor(Math.random() * CHARS.length)
    )
  }
  return autoId
}
