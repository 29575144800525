import "./../App.css";
import {FormInputGroup} from "./../components/FormInputGroup.js";
import {useEffect,useState} from "react";
import { useParams, useNavigate } from "react-router-dom";
import {db} from ".././firebase.js";
import { doc, increment, setDoc, updateDoc } from "firebase/firestore";
import {FirestoreAutoId} from ".././Methods.js";

export const RegisterUserPage = ({state,ShowModal,setState,Back,handleChange,ResetFields,translation}) => {

  const {eventID,activityGroupID,activityID} = useParams();
  const [doubleCheckActivity, setDoubleCheckActivity] = useState(false);
  const [doubleCheckGroup, setDoubleCheckGroup] = useState(false);
  const eventItem = state.events[eventID];
  const activityGroupItem = state.activityGroups[activityGroupID];
  const activityItem = state.activities[activityID];
  let emailCheckBoolActivity = false;
  let emailCheckBoolGroup = false;
  const navigate = useNavigate();
  const [userID, setUserID] = useState();
  const activityRef = doc(db, 'activities', activityID);

  useEffect(() => {
    ViewRegisterUserPage(eventID,activityGroupID,activityID);
    setUserID(FirestoreAutoId());
    //getEmails();
  },[])

  // const emails = [];
  // const firstNames = [];
  // const lastNames = [];

  // const getEmails = () => {
  //   Object.values(eventItem.activityGroups).forEach(activityGroup => {
  //     Object.values(activityGroup.activities).forEach(activity => {
  //       Object.values(activity.registered).forEach(user => {
  //         emails.push(user.email);
  //         firstNames.push(user.firstName);
  //         lastNames.push(user.lastName);
  //       });
  //     })
  //   })
  // }

  const emailCheck = (userType) => {
    if (!activityGroupItem.multiReg) {
      Object.values(state.users).forEach(childItem => {
        if (childItem.role === "user" && childItem.userType !== "cancelled" && childItem.email.toLowerCase() === state.email.toLowerCase() && childItem.activityGroupID === activityGroupID) emailCheckBoolGroup = true;
      })
    }
    Object.values(state.users).forEach(childItem => {
      if (childItem.role === "user" && childItem.userType !== "cancelled" && childItem.email.toLowerCase() === state.email.toLowerCase() && childItem.activityID === activityID) emailCheckBoolActivity = true;
    })
    let formCheck = false;
    if (!state.email || !state.firstName || !state.lastName || !state.gdpr || (!state.phone && state.phoneRequired) || (!state.address && state.addressRequired) || (!state.title && state.titleRequired) || (!state.comment && state.commentRequired) || (!state.institution && state.institutionRequired) || (!state.country && state.countryRequired)) formCheck = true;
    if (formCheck) ShowModal("alert", "Please complete required fields");
    else if (emailCheckBoolGroup) setDoubleCheckGroup(true);
    else if (emailCheckBoolActivity) setDoubleCheckActivity(true);
    else AddUser(eventID,activityGroupID,activityID,userType);
  }

  const ViewRegisterUserPage = (eventID,activityGroupID,activityID) => {
    setState((prevState) => ({
      ...prevState,
      activityName: activityItem.activityName,
      countryField: eventItem.countryField,
      institutionField: eventItem.institutionField,
      commentField: eventItem.commentField,
      titleField: eventItem.titleField,
      addressField: eventItem.addressField,
      phoneField: eventItem.phoneField,
      countryRequired: eventItem.countryRequired,
      institutionRequired: eventItem.institutionRequired,
      commentRequired: eventItem.commentRequired,
      titleRequired: eventItem.titleRequired,
      addressRequired: eventItem.addressRequired,
      phoneRequired: eventItem.phoneRequired
    }));
  }

  const AddUser = async (eventID,activityGroupID,activityID,userType) => {
    const timeStamp = Date.now();
    const userRef = doc(db, 'users', userID);
    const userObject = {
      "firstName": state.firstName,
      "lastName": state.lastName,
      "email": state.email,
      "gdpr": state.gdpr || false,
      "optIn": state.optIn || false,
      "emailConfirmation": state.emailConfirmation || false,
      "title": state.title || null,
      "phone": state.phone || null,
      "institution": state.institution || null,
      "address": state.address || null,
      "country": state.country || null,
      "comment": state.comment || null,
      "userID": userID,
      "userType": userType,
      "timeStamp": timeStamp,
      "lastUpdated": timeStamp,
      "eventID": eventID,
      "activityGroupID": activityGroupID,
      "activityID": activityID,
      "role": "user",
      "selfReg": null
    }
    setDoc(userRef,userObject);
    if (userType === "confirmed") {
      updateDoc(activityRef,{
        "ticketsLeft": increment(-1)
      })
    } 
    ShowModal("alert", userType === "confirmed" ? "User registered" : "User added to waiting list");
    navigate("/activity-overview/" + eventID + "/" + activityGroupID + "/" + activityID);
    ResetFields();
  }

  return (
    <div>
      <button className="secondary" onClick={() => Back()}>Back</button>
      <section id="container">
        <h1>{activityItem.ticketsLeft > 0 ? "Register for activity" : "Join waiting list"}</h1>
        <p>Activity: {activityItem.activityName}</p>
        <form autoComplete="off" onSubmit={(e) => {e.preventDefault(); emailCheck(activityItem.ticketsLeft > 0 ? "confirmed" : "waiting")}}>
          {eventItem.titleField && <FormInputGroup required={eventItem.titleRequired} type="title" title="Title" className={eventItem.titleRequired && !state.title ? "required" : null} value={state.title} handleChange={handleChange} name="title" />}
          <FormInputGroup title="First name" required={true} type="text" className={state.firstName ? null : "required"} value={state.firstName} handleChange={handleChange} name="firstName" />
          <FormInputGroup title="Last name" required={true}  type="text" className={state.lastName ? null : "required"} value={state.lastName} handleChange={handleChange} name="lastName" />
          <FormInputGroup title="Email address" required={true} type="email" className={state.email ? null : "required"} value={state.email} handleChange={handleChange} name="email" />
          {eventItem.phoneField && <FormInputGroup required={eventItem.phoneRequired} title="Phone" type="text" className={eventItem.phoneRequired && !state.phone ? "required" : null} value={state.phone} handleChange={handleChange} name="phone" />}
          {eventItem.institutionField && <FormInputGroup  required={eventItem.institutionRequired}title="Institution"type="text" className={eventItem.institutionRequired && !state.institution ? "required" : null} value={state.institution} handleChange={handleChange} name="institution" />}
          {eventItem.addressField && <FormInputGroup required={eventItem.addressRequired} title="Address" type="textarea" className={eventItem.addressRequired && !state.address ? "required" : null} value={state.address} handleChange={handleChange} name="address" />}
          {eventItem.countryField && <FormInputGroup required={eventItem.countryRequired} title="Country" type="country" className={eventItem.countryRequired && !state.country ? "required" : null} value={state.country} handleChange={handleChange} name="country" />}
          {eventItem.commentField && <FormInputGroup required={eventItem.commentRequired} title="Comment" type="textarea" className={eventItem.commentRequired && !state.comment ? "required" : null} value={state.comment} handleChange={handleChange} name="comment" />}
          <span className="gdpr">{translation[eventItem.language].gdprText}</span>
          <FormInputGroup title="I accept the privacy policy" type="checkbox" className={state.gdpr ? "checkboxGroup" : "checkboxGroup required"} checked={state.gdpr} handleChange={handleChange} name="gdpr" />
          <FormInputGroup title="I agree to be contacted by Hologic with information about products and services" type="checkbox" className="checkboxGroup" checked={state.optIn} handleChange={handleChange} name="optIn" />
          <FormInputGroup title="I want confirmation by email" type="checkbox" className="checkboxGroup" checked={state.emailConfirmation} handleChange={handleChange} name="emailConfirmation" />
          <button type="submit">{activityItem.ticketsLeft > 0 ? "Register" : "Add to Waiting list"}</button>
        </form>
      </section>
      {doubleCheckGroup && <div className="modal">
        <div>
          <p>Email already registered for this group of activities. Do you want to add another person using the same address?</p>
          <button onClick={() => { AddUser(eventID,activityGroupID,activityID,activityItem.ticketsLeft > 0 ? "confirmed" : "waiting"); setDoubleCheckGroup(false)}}>OK</button>
          <button onClick={() => setDoubleCheckGroup(false)}>Cancel</button>
        </div>
      </div>}
      {doubleCheckActivity && <div className="modal">
        <div>
          <p>Email already registered for this activity. Do you want to add another person using the same address?</p>
          <button onClick={() => { AddUser(eventID,activityGroupID,activityID,activityItem.ticketsLeft > 0 ? "confirmed" : "waiting"); setDoubleCheckActivity(false)}}>OK</button>
          <button onClick={() => setDoubleCheckActivity(false)}>Cancel</button>
        </div>
      </div>}
    </div>
  )
}
