import "./../App.css";
import {FormInputGroup} from "./../components/FormInputGroup.js";
import { useParams, useNavigate } from "react-router-dom";
import {db} from './../firebase';
import { doc, setDoc } from "firebase/firestore";
import {FirestoreAutoId} from "./../Methods.js";

export const RegisterAdminUserPage = ({state,ShowModal,setState,ResetFields,handleChange,Back}) => {

  const {eventID} = useParams();
  const navigate = useNavigate();
  const uid = FirestoreAutoId();
  const userRef = doc(db, 'users', uid);
  let emailCheckBool = false;

  const emailCheck = (userType) => {
    Object.values(state.users).forEach(childItem => {
      if (childItem.email.toLowerCase() === state.email.toLowerCase() && (childItem.role === "manager" || childItem.role === "reception")) emailCheckBool = true;
    })
    setState((prevState) => ({
      ...prevState,
      isLoading: true
    }));
    // If fields empty return
    if (!state.email || !state.password || !state.admin) {
      ShowModal("alert", "Please complete required fields");
      setState((prevState) => ({
        ...prevState,
        isLoading: false
      }));
      return;
    }
    // If email already exists
    else if (emailCheckBool) {
      ShowModal("alert", "Email address already exists");
      setState((prevState) => ({
        ...prevState,
        isLoading: false
      }));
      return;
    }
    // If password less too small
    else if (state.password.length < 6) {
      ShowModal("alert", "Password needs to be a minimum of 6 characters");
      setState((prevState) => ({
        ...prevState,
        isLoading: false
      }));
      return;
    }
    else CreateAdminUser(eventID, state.email, state.password, state.admin);
  }

  const CreateAdminUser = async (eventID, email, password, admin) => {
    const timeStamp = Date.now();
    setDoc(userRef, {
      "email": email,
      "password": password,
      "userID": uid,
      "eventID": eventID,
      "role": admin,
      "timeStamp": timeStamp,
      "lastUpdated": timeStamp
    });
    setState((prevState) => ({
      ...prevState,
      showLoginError: false,
      loginError: "",
      isLoading: false
    }));
    navigate("/admin");
    ShowModal("alert", "Admin user created");
    ResetFields();
  }

  if (state.role !=="super" && state.role !== "manager") return (
    <div>
      <button className="secondary" onClick={() => Back(true)}>Back</button>
      <section>
        <h1>You cannot access this page</h1>
      </section>
    </div>
  )
  return (
    <div>
      <button className="secondary" onClick={() => Back()}>Back</button>
      <section>
        <h1>Add user</h1>
        <form onSubmit={(e) => {e.preventDefault(); emailCheck()}}>
          <FormInputGroup type="admin" required={true} className={!state.admin ? "required" : null} handleChange={handleChange} name="admin" value={state.admin} title="User type" /> 
          {/* <FormInputGroup type="eventID" required={true} className={!state.eventID ? "required" : null} handleChange={handleChange} name="eventID" value={state.eventID} title="Event" />  */}
          <FormInputGroup type="email" required={true} className={!state.email ? "required" : null} handleChange={handleChange} name="email" value={state.email} title="Email address" />
          <FormInputGroup type="text" required={true} className={state.password.length < 6 ? "required" : null} handleChange={handleChange} name="password" value={state.password} title="Password (minimum of 6 characters)" />
          <button type="submit">Create user</button>
        </form>
        {state.showLoginError && <p className="error">{state.loginError}</p>}
      </section>
    </div>
  )
}
