import CSVDownloader from "./../components/CSVDownloader.js";
import {ExportTable} from "./../components/ExportTable.js";
import "./../App.css";

export const DataExportPage = ({state,Back}) => {
  if (state.role !=="super" && state.role !== "manager") return (
    <div>
      <button className="secondary" onClick={() => Back(true)}>Back</button>
      <section>
        <h1>You cannot access this page</h1>
      </section>
    </div>
  )
  return (
    <div>
      <div className="justify justifyMobile">
        <button className="secondary" onClick={() => Back(true)}>Back</button>
        {state.role === "super" && <CSVDownloader state={state} />}
      </div>
      <section>
        <h1>Export participant data</h1>
        <ExportTable state={state} />
      </section>
    </div>
  )
}
