import "./../App.css";
import {FormInputGroup} from "./../components/FormInputGroup.js";
import { useParams, useNavigate } from "react-router-dom";
import {useState, useEffect} from "react";
import {db, storage} from './../firebase';
import { doc, updateDoc, deleteDoc, query, collection, where, getDocs } from "firebase/firestore";
import { ref, deleteObject, listAll } from "firebase/storage";

export const EditActivityGroupPage = ({state,setState,ResetFields,handleChange,Back,ShowModal}) => {

  useEffect(() => {
    ViewEditActivityGroupPage();
  },[])

  const navigate = useNavigate();
  const {eventID, activityGroupID} = useParams();
  const [deleteCheck, setDeleteCheck] = useState(false);
  const eventItem = state.events[eventID];
  const activityGroupItem = state.activityGroups[activityGroupID];
  const activityGroupRef = doc(db, "activityGroups", activityGroupID);

  const ViewEditActivityGroupPage = () => {
    if (!eventItem || !activityGroupItem) return;
    setState((prevState) => ({
      ...prevState,
      eventName: eventItem.eventName,
      activityGroupName: activityGroupItem.activityGroupName,
      multiReg: activityGroupItem.multiReg
    }));
  }

  const DeleteActivityGroup = async() => {
    deleteDoc(activityGroupRef);
    const q = query(collection(db, "activities"), where("activityGroupID", "==", activityGroupID));
    const queryActivities = await getDocs(q);
    queryActivities.forEach((doc) => {
      deleteDoc(doc.ref);
    });
    DeleteActivityUsers(activityGroupID);
    DeleteGroupStorage(`/activity-pdfs/${eventID}/${activityGroupID}/`);
    DeleteGroupStorage(`/qr-tickets/${eventID}/${activityGroupID}/`);
    navigate("/");
    ResetFields();
  }

  const DeleteGroupStorage = async (path) => {
    const listRef = ref(storage, path);
    // Find all the prefixes and items.
    listAll(listRef)
    .then((res) => {
      res.prefixes.forEach((folderRef) => {
        listAll(folderRef)
        .then((resChild) => {
          resChild.items.forEach((itemRef) => {
            // Delete the file
            deleteObject(itemRef).then(() => {
              // File deleted successfully
            }).catch((error) => {
              // Uh-oh, an error occurred!
            });
          });
        }).catch((error) => {
          // Uh-oh, an error occurred!
        });
      });
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });
  }

  const DeleteActivityUsers = async(activityGroupID) => {
    const q = query(collection(db, "users"), where("activityGroupID", "==", activityGroupID));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      deleteDoc(doc.ref);
    });
  }

  const EditActivityGroup = async () => {
    updateDoc(activityGroupRef,{
      "activityGroupName": state.activityGroupName,
      "multiReg": state.multiReg
    })
    navigate("/");
    ResetFields();
  }

  if (state.role !=="super" && state.role !== "manager") return (
    <div>
      <button className="secondary" onClick={() => Back(true)}>Back</button>
      <section>
        <h1>You cannot access this page</h1>
      </section>
    </div>
  )
  if (activityGroupItem) return (
    <div>
      <button className="secondary" onClick={() => Back(true)}>Back</button>
      <section>
        <h1>Edit activity group</h1>
        <form onSubmit={(e) => {e.preventDefault(); state.activityGroupName ? EditActivityGroup() : ShowModal("alert", "Please complete name field")}}>
          <FormInputGroup disabled={true} type="textarea" value={state.eventName} handleChange={handleChange} title="Event name" name="eventName" />
          <FormInputGroup required={true} className={!state.activityGroupName ? "required" : null} type="textarea" value={state.activityGroupName} handleChange={handleChange} title="Activity group name" name="activityGroupName" />
          <div className="card settings">
            <h5>Settings</h5>
            <div className="inner">
              <FormInputGroup title="Allow multiple registrations in group" type="checkbox" className="checkboxGroup" checked={state.multiReg} handleChange={handleChange} name="multiReg" />
            </div>
          </div>
          <div className="justifyMobile">
            <button type="submit" className="half">Save activity group</button>
            <button className="half edit" onClick={(e) => {e.preventDefault(); setDeleteCheck(true)}}>Delete activity group</button>
          </div>
        </form>
      </section>
      {deleteCheck && <div className="modal">
        <div>
          <p>Are you sure? This will delete any activities and participants as well.</p>
          <button onClick={() => { DeleteActivityGroup(); setDeleteCheck(false)}}>OK</button>
          <button onClick={() => setDeleteCheck(false)}>Cancel</button>
        </div>
      </div>}
    </div>
  )
  else return (
    <div>
      <button className="secondary" onClick={() => Back()}>Back</button>
      <section>
        <div>
        <h1>Page not found</h1>
        <p>Activity group does not exist</p>
        </div>
      </section>
    </div>
  );
}
