import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.min.js',
//     import.meta.url,
//   ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


export const PDF = ({url}) => {

	/** 1. Set a state variable at the top-level of your application: */

	const [allPageNumbers, setAllPageNumbers] = React.useState(); // default value is undefined.

	// These are just for fun ;)
	const [outerWidth, setOuterWidth] = React.useState();

	/** 2. Then, write the function: */

	// Document's onLoadSuccess function returns a PDFDocumentProxy type
	function onDocumentLoadSuccess(pdf) {
		const allPageNumbers = []; // array of numbers
		for (let p = 1; p < pdf.numPages + 1; p++) {
			allPageNumbers.push(p);
		}
		setAllPageNumbers(allPageNumbers);

		// just for fun
		setOuterWidth(document.getElementById('pdf-container').offsetWidth);
	}

	/** Finally, since you have all the page numbers in state,
	 *  you can just loop through the map and render each page
	 *  by their page number (`pn`): */

	return (
		<div
			id="pdf-container"
			// The styling here is just for fun.
			style={{
				backgroundColor: 'white',
				border: 'none',
				display: 'flex',
				width: '100%'
			}}>
			<Document
				file={url} // example PDF file located in '/public/assets/docs/*'
				onLoadSuccess={onDocumentLoadSuccess}>
				<div
					style={{
						maxHeight: '100vh', // needed for scroll
						overflowY: 'scroll', // yes vertical scroll
						overflowX: 'hidden', // no horizontal scroll
					}}>
					{allPageNumbers
						? allPageNumbers.map((pn) => (
								<Page key={`page-${pn}`} width={outerWidth} pageNumber={pn} /> // 'width' is just for fun.
						  ))
						: undefined}
					<div style={{height:'150px'}}/>
				</div>
			</Document>
		</div>
	);
};