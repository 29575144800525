import "./../App.css";
import { AdminTable } from "./../components/AdminTable.js";
import { useNavigate } from "react-router-dom";
import {CompareStartDate} from "./../Methods.js";

export const EditAdminUsersPage = ({ Back, state }) => {

  const navigate = useNavigate();
  // const users = Object.values(state.users).filter(e => e.role === "manager" || e.role === "reception");

  if (state.role !== "super" && state.role !== "manager") return (
    <div>
      <button className="secondary" onClick={() => Back(true)}>Back</button>
      <section>
        <h1>You cannot access this page</h1>
      </section>
    </div>
  )
  return (
    <div>
      <button className="secondary" onClick={() => Back(true)}>Back</button>
      <section>
        <h1>Admin users</h1>
          {Object.values(state.events)
            .sort(CompareStartDate)
            .filter(e => state.role === "manager" ? state.receptionEventID === e.eventID : e)
            .map((eventItem,eventIndex) => (
              <section className="event" key={eventIndex}>
                <div className="justify">
                  <h2 className="limit">{eventItem.eventName}</h2>
                  <div><button onClick={() => navigate("/register-admin/" + eventItem.eventID)}>Create new admin <i className="fas fa-plus"></i></button></div>
                </div>
                {Object.values(state.users).filter(e => e.eventID === eventItem.eventID && (e.role === "manager" || e.role === "reception")).length > 0 ? <AdminTable eventID={eventItem.eventID} state={state} /> : <p>No admin users</p>}
              </section>
          ))}

        {/* <div className="justify">
          <h1>Admin users</h1>
          <div><button onClick={() => navigate("/register-admin")}>Create new admin <i className="fas fa-plus"></i></button></div>
        </div>
        <section className="event">
          <AdminTable users={users} events={state.events} />
        </section> */}
      </section>
    </div>
  )
}
